import React from 'react'
import { capitalize } from 'lodash'
import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import Table from '@src/components/TableV2/Table'
import { Text, Token } from '@revolut/ui-kit'
import { formatPeriod } from '@src/utils/format'
import { getReviewCycleStatusColor } from '@src/utils/reviewCycles'

export const reviewCyclesNameColumnV2: ColumnInterface<ReviewCyclesInterface> = {
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Cycle name',
  insert: ({ children }) => (
    <Table.ItemCell useIcon="DocumentsPair" iconProps={{ color: Token.color.foreground }}>
      <Table.ItemCell.Title>{children}</Table.ItemCell.Title>
    </Table.ItemCell>
  ),
}

// TODO: remove when new tables are fully released
export const reviewCyclesNameColumn: ColumnInterface<ReviewCyclesInterface> = {
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Name',
}

export const reviewCyclesTimeframeColumn: ColumnInterface<ReviewCyclesInterface> = {
  type: CellTypes.insert,
  idPoint: 'timeframe',
  dataPoint: 'timeframe',
  sortKey: 'start_date_time',
  filterKey: 'start_date_time',
  filterType: FilterType.date,
  selectorsKey: selectorKeys.none,
  title: 'Timeframe',
  insert: ({ data }) => (
    <Text>{formatPeriod(data.start_date_time, data.end_date_time)}</Text>
  ),
}

export const reviewCyclesStartDateColumn: ColumnInterface<ReviewCyclesInterface> = {
  type: CellTypes.date,
  idPoint: 'start_date_time',
  dataPoint: 'start_date_time',
  sortKey: 'start_date_time',
  filterKey: 'start_date_time',
  selectorsKey: selectorKeys.none,
  title: 'Start Date',
}

export const reviewCyclesEndDateColumn: ColumnInterface<ReviewCyclesInterface> = {
  type: CellTypes.date,
  idPoint: 'end_date_time',
  dataPoint: 'end_date_time',
  sortKey: 'end_date_time',
  filterKey: 'end_date_time',
  selectorsKey: selectorKeys.none,
  title: 'End Date',
}

// TODO: remove when new tables are fully released
export const reviewCyclesStatusColumnV2: ColumnInterface<ReviewCyclesInterface> = {
  type: CellTypes.insert,
  idPoint: 'status',
  dataPoint: 'status',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.reviewCycleStatuses,
  title: 'Status',
  insert: ({ data: { status } }) =>
    status ? <Table.StatusCell status={{ id: status, name: capitalize(status) }} /> : '-',
}

export const reviewCyclesStatusColumn: ColumnInterface<ReviewCyclesInterface> = {
  type: CellTypes.text,
  idPoint: 'status',
  dataPoint: 'status',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.requisitions_status,
  colors: (data: ReviewCyclesInterface) => {
    return getReviewCycleStatusColor(data.status)
  },
  title: 'Status',
}
