import React, { useCallback, useMemo, useState } from 'react'
import { useOrdering } from '@components/TableV2/hooks'
import { SORT_DIRECTION } from '@src/interfaces/data'
import { Statuses } from '@src/interfaces'
import {
  initialRequisitionStatusFilter,
  RequisitionInterface,
} from '@src/interfaces/requisitions'
import { requisitionsRequests, updateRequisitionsOrder } from '@src/api/requisitions'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  requisitionBackfillColumn,
  requisitionCandidatesColumn,
  requisitionPriorityColumn,
  requisitionQueuePosition,
} from '@src/constants/columns/requisition'
import { SpecialisationInterface } from '@src/interfaces/roles'
import { filterSortPageIntoQuery } from '@src/utils/table'
import Stat from '@components/Stat/Stat'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import OrderingTableControls from '@src/features/OrderingTableControls/OrderingTableControls'
import { PermissionTypes } from '@src/store/auth/types'
import { OrderingDataType, UpdateOrderingInterface } from '@src/interfaces/ordering'
import ReferCandidateButton from '@components/ReferCandidateButton/ReferCandidateButton'
import AddRequisitionButton from '@src/features/CommonRequisitionTable/AddRequisitionButton'
import { TableNames } from '@src/constants/table'
import { useRequisitionTable } from '@src/features/CommonRequisitionTable/CommonRequisitionTable'
import { useGetRequisitionSettings } from '@src/api/settings'
import { TalentType } from '@src/interfaces/talent/talent'
import { AllowedExportMenu } from '@src/features/ExportMenu/AllowedExportMenu'
import { useLapeContext } from '@src/features/Form/LapeForm'
import Table from '@components/TableV2/Table'
import { useIsNewTable } from '@components/TableV2/hooks'
import { getCommonRequisitionTableRow } from '@src/features/CommonRequisitionTable/commonRequisitionTableRow'
import { specialisationRoleNameColumn } from '@src/constants/columns/role'

interface StructureProps {
  data: SpecialisationInterface
}

const Requisitions = ({ data }: StructureProps) => {
  const isNewTable = useIsNewTable()
  const { values } = useLapeContext<SpecialisationInterface>()
  const [orderingMode, setOrderingMode] = useState(false)
  const [activeOrderingRow, setActiveOrderingRow] = useState<number | null>()
  const { data: requisitionSettings } = useGetRequisitionSettings()

  const isHiringColumnsEnabled = requisitionSettings?.enable_table_hiring_fields

  const tableSettings = useMemo(
    () =>
      isNewTable
        ? {
            hidden: [
              specialisationRoleNameColumn.title,
              requisitionQueuePosition.title,
              requisitionCandidatesColumn.title,
              requisitionBackfillColumn.title,
            ],
            visible: [],
          }
        : {
            hidden: [requisitionBackfillColumn.title],
            visible: [],
          },
    [isNewTable],
  )

  const hiddenCells: Partial<Record<keyof RequisitionInterface, boolean>> = {
    [requisitionQueuePosition.idPoint]: !isHiringColumnsEnabled,
    [requisitionCandidatesColumn.idPoint]: !isHiringColumnsEnabled,
    [requisitionPriorityColumn.idPoint]: !isHiringColumnsEnabled,
  }

  const initialFilter = [
    {
      filters: [{ name: data.name, id: data.id }],
      columnName: 'specialisation__id',
      nonResettable: true,
    },
    {
      filters: initialRequisitionStatusFilter,
      columnName: 'status',
    },
    {
      filters: [
        { name: OrderingDataType.Specialisation, id: OrderingDataType.Specialisation },
      ],
      columnName: 'parent_type',
      nonResettable: true,
    },
    {
      filters: [{ name: data.name, id: data.id }],
      columnName: 'parent_id',
      nonResettable: true,
    },
    {
      filters: [{ name: 'True', id: 'True' }],
      columnName: 'include_non_prioritised_items',
      nonResettable: true,
    },
  ]
  const initialSortBy = [
    {
      sortBy: 'pipeline_queue_position',
      direction: SORT_DIRECTION.DESC,
      nonResettable: true,
    },
  ]

  const table = useRequisitionTable({
    filterBy: initialFilter,
    sortBy: initialSortBy,
    statsData: {
      filters: [
        {
          filters: [{ name: data.name, id: data.id }],
          columnName: 'specialisation__id',
        },
      ],
    },
  })

  const onAfterChange = async (requestData: UpdateOrderingInterface) => {
    try {
      await updateRequisitionsOrder(requestData, initialFilter)
    } catch (e) {
      // if something went wrong - cancel optimistic changes
      table.refresh()
    }
  }

  const {
    onChangeOrder,
    onChangePosition,
    moveToTop,
    moveToBottom,
    selectedOrderingIds,
    setSelectedOrderingIds,
  } = useOrdering(table.data, table.setData, table.count, table.refresh, onAfterChange)

  const onHandleChangePosition = useCallback(
    (id: number, positionNumber: number) => {
      onChangePosition(id, positionNumber)

      setActiveOrderingRow(id)
      setTimeout(() => {
        setActiveOrderingRow(null)
      }, 1200)
    },
    [onChangePosition],
  )

  const row = useMemo(
    () =>
      getCommonRequisitionTableRow({
        isNewTable,
        stats: table.stats,
        orderingMode,
        onChangePosition: onHandleChangePosition,
        isConfidential: false,
      }),
    [isNewTable, table.stats, orderingMode, onHandleChangePosition],
  )

  const onChangeOrderingMode = (isOrdering: boolean) => {
    if (isOrdering) {
      table.resetFiltersAndSorting([
        {
          filters: [{ name: 'False', id: 'False' }],
          columnName: 'include_non_prioritised_items',
          nonResettable: true,
        },
        {
          filters: [{ name: Statuses.opened, id: Statuses.opened }],
          columnName: 'status',
        },
      ])
    } else {
      table.resetFiltersAndSorting(initialFilter)
    }
    setOrderingMode(isOrdering)
  }

  const handleRowEdit = (item: RequisitionInterface) => {
    navigateTo(pathToUrl(ROUTES.FORMS.REQUISITION.ROLE, { id: item.id }))
  }

  const filterQuery = filterSortPageIntoQuery(table.sortBy, table.filterBy, 1)
  const canReorder =
    isHiringColumnsEnabled &&
    data.field_options.permissions?.includes(PermissionTypes.ReorderRequisitions)

  return (
    <Table.Widget>
      <Table.Widget.Info>
        <Stat
          mr="s-16"
          label="Total Headcount"
          val={table?.stats?.requisition_total_headcount}
        />
        <Stat
          label="Remaining Headcount"
          val={table?.stats?.requisition_remaining_headcount}
        />
      </Table.Widget.Info>
      <Table.Widget.Actions>
        <Table.Widget.MoreBar maxCount={3}>
          {canReorder && (
            <OrderingTableControls
              disabled={false}
              orderingMode={orderingMode}
              onChangeMode={onChangeOrderingMode}
              moveToTop={moveToTop}
              moveToBottom={moveToBottom}
              disabledMoveButtons={!selectedOrderingIds?.length}
            />
          )}
          {!orderingMode && (
            <>
              <AddRequisitionButton
                newItemInitialValues={{
                  specialisation: {
                    id: values.id,
                    name: values.name,
                    role: values.role,
                  },
                }}
              />
              <ReferCandidateButton />
              <AllowedExportMenu
                request={requisitionsRequests.getExport}
                filterQuery={filterQuery}
                fileName="Requisitions"
                type={TalentType.Specialisation}
              />
            </>
          )}
          <Table.ColumnsSettingsButton />
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Table>
        <AdjustableTable
          name={TableNames.SpecialisationRequisitions}
          dataType="Requisition"
          row={row}
          {...table}
          onRowClick={orderingMode ? undefined : handleRowEdit}
          noDataMessage="All requisitions will appear here."
          orderingMode={orderingMode}
          disabledFiltering={orderingMode}
          setSelectedOrderingIds={setSelectedOrderingIds}
          selectedOrderingIds={selectedOrderingIds}
          onChangeOrder={onChangeOrder}
          activeOrderingRow={activeOrderingRow}
          hiddenCells={hiddenCells}
          hideCount={!!isNewTable}
          useWindowScroll
          tableSettings={tableSettings}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}

export default Requisitions
