import React from 'react'
import { Text } from '@revolut/ui-kit'

import { ColorTag, ColorTagVariant } from '@components/ColorTag/ColorTag'
import { getStatusColor as getLegacyStatusColor } from '@components/CommonSC/General'
import { useIsNewTable } from '@components/TableV2/hooks'
import { IdAndName, Statuses } from '@src/interfaces'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { QueryStatuses } from '@src/interfaces/dataAnalytics'
import { DocumentStatuses } from '@src/interfaces/documents'
import { IdStatuses } from '@src/interfaces/employees'
import { InterviewFeedbackStatus, InterviewStatuses } from '@src/interfaces/interviewTool'
import { PublishingStatuses } from '@src/interfaces/jobPosting'
import { ReviewCycleStatus } from '@src/interfaces/reviewCycles'

type Status =
  | Statuses
  | IdStatuses
  | ApprovalStatuses
  | InterviewStatuses
  | InterviewFeedbackStatus
  | QueryStatuses
  | DocumentStatuses
  | PublishingStatuses
  | ReviewCycleStatus

interface StatusCellProps {
  status: IdAndName<Status>
}

const getStatusVariant = (status: Status): ColorTagVariant => {
  switch (status) {
    case Statuses.open:
    case Statuses.active:
    case ApprovalStatuses.Approved:
    case DocumentStatuses.completed:
    case PublishingStatuses.fully_published:
    case PublishingStatuses.published_internally:
    case ReviewCycleStatus.ongoing:
      return 'success'

    case Statuses.preapproved:
    case Statuses.funcapproved:
    case Statuses.extended:
    case Statuses.applied:
    case Statuses.pending:
    case Statuses.pending_approval:
    case Statuses.pending_reapproval:
    case Statuses.pending_completion:
    case Statuses.planned:
    case IdStatuses.onboarding:
    case Statuses.requires_changes:
    case Statuses.at_risk:
    case InterviewStatuses.pending_scheduling:
    case InterviewStatuses.pending_candidate_response:
    case InterviewStatuses.pending_candidate_signature:
    case InterviewStatuses.awaiting_interviewer_availability:
    case InterviewStatuses.awaiting_feedback:
    case InterviewStatuses.pending_cv_screening:
    case InterviewFeedbackStatus.pending:
    case InterviewFeedbackStatus.draft:
    case ApprovalStatuses.Pending:
    case DocumentStatuses.pending:
    case DocumentStatuses.pending_upload:
    case DocumentStatuses.pending_approval:
      return 'warning'

    case Statuses.revoked:
    case Statuses.cancelled:
    case Statuses.canceled:
    case Statuses.terminated:
    case Statuses.delayed:
    case InterviewStatuses.interviewer_rejected:
    case InterviewStatuses.scheduling_expired:
    case InterviewStatuses.interview_cancelled:
    case InterviewFeedbackStatus.rejected:
    case ApprovalStatuses.Rejected:
      return 'danger'

    case Statuses.archived:
    case Statuses.expired:
    case Statuses.inactive:
    case Statuses.draft:
    case Statuses.future:
    case Statuses.opened:
    case Statuses.unassigned:
    case PublishingStatuses.closed:
    case PublishingStatuses.unpublished:
    case Statuses.closed:
    case Statuses.approved:
    case Statuses.completed:
    case InterviewFeedbackStatus.rejected:
    case Statuses.rejected:
      return 'neutral'

    default:
      return 'default'
  }
}

export const StatusCell = ({ status }: StatusCellProps) => {
  const isNewTable = useIsNewTable()

  if (!isNewTable) {
    return <Text color={getLegacyStatusColor(status.id)}>{status.name}</Text>
  }

  const statusVariant = getStatusVariant(status.id)

  return <ColorTag variant={statusVariant}>{status.name}</ColorTag>
}
