import React from 'react'
import { Flex, Icon, Text, Token, Widget } from '@revolut/ui-kit'
import { useGetEmployeeTotalCompensationStats } from '@src/api/totalCompensation'
import { CurrencySelect } from '@components/CurrencySelect/CurrencySelect'
import Tooltip from '@components/Tooltip/Tooltip'
import { CompensationParams } from '@src/pages/EmployeeProfile/Layout/common/types'
import { PieChart } from '../Charts/PieChart'

type Props = {
  compensationParams: CompensationParams
  id: number
}

export const EquityTotal = ({ compensationParams, id }: Props) => {
  const { currency, setCurrency } = compensationParams
  const filters = currency
    ? [
        {
          filters: [{ id: `${currency}`, name: `${currency}` }],
          columnName: 'currency_iso_code',
        },
      ]
    : []

  const { data, isLoading } = useGetEmployeeTotalCompensationStats(id, filters)

  if (!isLoading && !data) {
    return null
  }

  const pieChartData = [
    {
      name: 'Vested',
      value: data ? Number(data.total_vested_equity) : 0,
    },
    {
      name: 'Unvested',
      value: data ? Number(data.total_unvested_equity) : 0,
      color: Token.color.greyTone50,
    },
  ]

  return (
    <Widget data-testid="total_equity_section" height="55vh" p="s-16">
      <Flex alignItems="stretch" flexDirection="column" height="100%">
        <Flex alignItems="center" gap="s-4">
          <Text variant="emphasis2">Total equity</Text>
          <Tooltip
            placement="bottom"
            text="The total value of your equity, including both vested and unvested shares."
          >
            <Icon name="InfoOutline" size={16} />
          </Tooltip>
        </Flex>

        <CurrencySelect
          label=""
          onCurrencyChange={({ iso_code }) => setCurrency(iso_code)}
          value={data?.currency.iso_code}
        />

        <PieChart
          currency={data?.currency.iso_code}
          data={pieChartData}
          isLoading={isLoading}
          total={data ? Number(data.total_equity) : undefined}
        />
      </Flex>
    </Widget>
  )
}
