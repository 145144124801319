import React from 'react'
import {
  getDeleteRoadmapColumn,
  roadmapDueDate,
  roadmapEpicUrlColumn,
  roadmapGenericNameColumn,
  roadmapJiraLabelColumn,
  roadmapOwnerColumn,
  roadmapPriorityColumn,
  roadmapProgressColumn,
  roadmapResolutionAtColumn,
  roadmapStartDate,
  roadmapStatusColumn,
} from '../../constants/columns/roadmap'
import { RoadmapInterface } from '@src/interfaces/roadmaps'
import {
  FilterByInterface,
  RowInterface,
  SORT_DIRECTION,
  SortByInterface,
  Stats,
} from '../../interfaces/data'
import AdjustableTable, {
  AdjustableTableProps,
} from '@src/components/TableV2/AdjustableTable'
import { roadmapsExtraRequests, roadmapsRequests } from '@src/api/roadmaps'
import { useShowRoadmapDetails, useRoadmapContext } from '.'
import { useTable } from '@src/components/Table/hooks'
import {
  RoadmapChangelogContext,
  useRoadmapChangelog,
} from '../RoadmapChangelog/RoadmapChangelog'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { useGetRoadmapSettings } from '@src/api/settings'
import { TableNames } from '@src/constants/table'
import { DeleteActionProps } from '@src/components/ColumnInserts/DeleteAction/DeleteAction'

type CommonTableProps = Pick<
  AdjustableTableProps<RoadmapInterface>,
  | 'dataType'
  | 'expandableType'
  | 'name'
  | 'noDataMessage'
  | 'onRowClick'
  | 'useWindowScroll'
>
export interface RoadMapTablesProps {
  defaultFilters: FilterByInterface[]
  tableProps: { name: TableNames } & Partial<CommonTableProps>
  allowEdit: boolean
  defaultSorting?: SortByInterface[]
  reviewCycle?: ReviewCyclesInterface
  row?: RowInterface<RoadmapInterface>
}
export const commonRoadmapSorting = [
  {
    sortBy: 'priority',
    direction: SORT_DIRECTION.DESC,
  },
  {
    sortBy: 'due_date',
    direction: SORT_DIRECTION.DESC,
  },
]
export const useRenderRoadmapTables = (props: RoadMapTablesProps) => {
  const { defaultFilters, defaultSorting, reviewCycle, tableProps, allowEdit, row } =
    props
  const sorting = defaultSorting || commonRoadmapSorting

  const { data: roadmapSettings } = useGetRoadmapSettings()
  const jiraEnabled = roadmapSettings?.jira_epics_enabled
  const ctx = useRoadmapContext()
  const table = useTable<RoadmapInterface, Stats>(
    roadmapsRequests,
    defaultFilters,
    sorting,
  )
  const tableWithChangeLog = useTable<RoadmapInterface, Stats>(
    roadmapsExtraRequests,
    defaultFilters,
    sorting,
  )
  const { changelogState, changelogSwitcher } = useRoadmapChangelog(reviewCycle || null)
  const { renderSidebar, showRoadmapDetails } = useShowRoadmapDetails({
    changelogState: changelogState.active,
    jiraEnabled: !!jiraEnabled,
  })

  const renderTable = (override: Partial<AdjustableTableProps<RoadmapInterface>>) => {
    const readOnlyRows: RowInterface<RoadmapInterface> = row || {
      cells: [
        {
          ...roadmapGenericNameColumn,
          width: 200,
        },
        {
          ...roadmapPriorityColumn,
          width: 100,
        },
        {
          ...roadmapProgressColumn,
          width: 150,
        },
        {
          ...roadmapStatusColumn,
          width: 120,
        },
        {
          ...roadmapStartDate,
          width: 130,
        },
        {
          ...roadmapDueDate,
          width: 130,
        },
        {
          ...roadmapResolutionAtColumn,
          width: 100,
        },
        {
          ...roadmapOwnerColumn,
          width: 90,
        },
        {
          ...roadmapJiraLabelColumn,
          width: 90,
        },
        {
          ...roadmapEpicUrlColumn,
          width: 90,
        },
      ],
    }
    const getEditableRows = (
      handleDelete: DeleteActionProps['handleDelete'],
    ): RowInterface<RoadmapInterface> => {
      return {
        cells: [
          ...readOnlyRows.cells,
          {
            ...getDeleteRoadmapColumn({ handleDelete }),
            width: 70,
          },
        ],
      }
    }
    const currentTable = changelogState.active ? tableWithChangeLog : table
    const currentTableProps: AdjustableTableProps<RoadmapInterface> = {
      ...currentTable,
      dataType: ctx.dataType,
      pendingDataType: ctx.isLoading,
      noDataMessage: ctx.noDataMessage,
      expandableType: 'chevron',
      onRowClick: showRoadmapDetails,
      useWindowScroll: true,
      count: currentTable.data.length,
      data: currentTable.data,
      row:
        allowEdit && !changelogState.active
          ? getEditableRows(async (itemId: string | number) => {
              await roadmapsExtraRequests.deleteItem(itemId)
              table.refresh()
            })
          : readOnlyRows,
      ...tableProps,
      ...override,
    }

    return (
      <RoadmapChangelogContext.Provider value={changelogState}>
        <AdjustableTable<RoadmapInterface> {...currentTableProps} />
        {renderSidebar({ canEdit: allowEdit, onAfterUpdate: () => table.refresh() })}
      </RoadmapChangelogContext.Provider>
    )
  }
  return { renderTable, tableWithChangeLog, table, changelogSwitcher, changelogState }
}
