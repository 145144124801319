import { Highlights, DataPoint, DataPointSkeleton } from '@revolut/ui-kit'
import React, { forwardRef, PropsWithChildren } from 'react'
import { UseFetchResult } from '@src/interfaces'
import { pathToUrl } from '@src/utils/router'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { useRouteMatch } from 'react-router-dom'
import { useGlobalSettings } from '@src/api/settings'
import { useSelector } from 'react-redux'
import { selectFeatureFlags, selectPermissions } from '@src/store/auth/selectors'
import {
  NavigationConfigInterface,
  CanViewInterface,
} from '@components/StatNavigation/types'

interface StatNavigationProps<T> {
  api: () => UseFetchResult<T>
  config: NavigationConfigInterface<T>[]
}

const DataPointLink = forwardRef<HTMLAnchorElement, PropsWithChildren<{ to: string }>>(
  ({ to, ...rest }, ref) => (
    <DataPoint
      ref={ref}
      aria-selected={Boolean(useRouteMatch({ path: to, exact: true }))}
      use={InternalLink}
      to={to}
      {...rest}
    />
  ),
)

export const StatNavigation = <T,>({ api, config }: StatNavigationProps<T>) => {
  const { data, isLoading } = api()
  const featureFlags = useSelector(selectFeatureFlags)
  const permissions = useSelector(selectPermissions)
  const globalSettings = useGlobalSettings()

  const permissionFilter = (entry?: CanViewInterface) => {
    if (!entry) {
      return true
    }
    const checkPermissions = entry?.permissions
      ? entry.permissions.some(permission => permissions.includes(permission))
      : true
    const checkFeatureFlags = entry?.featureFlags
      ? entry.featureFlags.some(flag => featureFlags.includes(flag))
      : true
    const checkGlobalSettings = entry?.globalSettings
      ? entry.globalSettings.some(setting => Boolean(globalSettings?.settings?.[setting]))
      : true

    return checkPermissions && checkFeatureFlags && checkGlobalSettings
  }

  const entries = config.filter(entry => permissionFilter(entry?.canView))

  if (!entries || entries.length === 0) {
    return null
  }

  return (
    <Highlights variant="tabs">
      {entries.map(entry => (
        <DataPointLink to={pathToUrl(entry.to)} key={entry.label}>
          {isLoading ? (
            <DataPointSkeleton.Value />
          ) : (
            <DataPoint.Value>{data?.[entry.key]}</DataPoint.Value>
          )}
          <DataPoint.Label>{entry.label}</DataPoint.Label>
        </DataPointLink>
      ))}
    </Highlights>
  )
}
