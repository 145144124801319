import React from 'react'
import { useIsNewTable, useTable } from '@components/TableV2/hooks'
import { InterviewFeedbackInterface } from '@src/interfaces/interviewTool'
import { getInterviewFeedbacks } from '@src/api/recruitment/interviews'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import {
  interviewCandidate,
  interviewDateColumn,
  interviewNameColumn,
  interviewRatingColumn,
  interviewRole,
  interviewScorecardColumn,
  interviewSeniority,
  interviewStatus,
} from '@src/constants/columns/interviews'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { InterviewStats } from '@src/pages/Recruitment/RecruitmentSubTabs/Interviews/InterviewStats'
import { StagesBankAction } from '@src/pages/Recruitment/RecruitmentSubTabs/Interviews/Actions/StagesBankAction'
import { ManageInterviewingScorecardsAction } from '@src/pages/Recruitment/RecruitmentSubTabs/Interviews/Actions/ManageInterviewingScorecardsAction'
import { RecruitmentGroupsAction } from '@src/pages/Recruitment/RecruitmentSubTabs/Interviews/Actions/RecruitmentGroupsAction'
import { OnboardingAppScreen } from '@src/pages/OnboardingChecklistV2/components/OnboardingAppScreen'
import { InterviewAvailabilityAction } from '@src/features/InterviewAvailability/InterviewAvailabilityAction'
import Table from '@components/TableV2/Table'

const rows: RowInterface<InterviewFeedbackInterface> = {
  linkToForm: data =>
    navigateTo(
      pathToUrl(ROUTES.FORMS.INTERVIEW_FEEDBACK.GENERAL, {
        id: data.id,
      }),
    ),
  cells: [
    {
      ...interviewCandidate,
      width: 250,
    },
    {
      ...interviewStatus,
      width: 140,
    },
    {
      ...interviewRole,
      width: 200,
    },
    {
      ...interviewSeniority,
      width: 150,
    },
    {
      ...interviewNameColumn,
      width: 160,
    },
    {
      ...interviewDateColumn,
      width: 200,
    },
    {
      ...interviewRatingColumn,
      width: 150,
    },
    {
      ...interviewScorecardColumn,
      width: 110,
    },
  ],
}

const InterviewsBase = () => {
  const isNewTable = useIsNewTable()
  const table = useTable<InterviewFeedbackInterface>(
    { getItems: getInterviewFeedbacks },
    undefined,
    [
      {
        sortBy: 'status_order',
        direction: SORT_DIRECTION.DESC,
      },
      {
        sortBy: 'scheduled_date_time',
        direction: SORT_DIRECTION.ASC,
      },
    ],
  )

  return (
    <Table.Widget>
      <Table.Widget.Info>
        <InterviewStats filters={table.filterBy} onFilterChange={table.onFilterChange} />
      </Table.Widget.Info>
      <Table.Widget.Actions>
        <Table.Widget.MoreBar maxCount={3}>
          <InterviewAvailabilityAction />
          <StagesBankAction />
          <ManageInterviewingScorecardsAction />
          <RecruitmentGroupsAction />
          <Table.ColumnsSettingsButton />
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Table>
        <AdjustableTable<InterviewFeedbackInterface>
          name={TableNames.Interviews}
          useWindowScroll
          dataType="Interviews"
          row={rows}
          hideCount={!!isNewTable}
          {...table}
          noDataMessage="Interviews will appear here."
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}

export const Interviews = () => {
  return (
    <OnboardingAppScreen category="interviews">
      <InterviewsBase />
    </OnboardingAppScreen>
  )
}
