import React from 'react'
import { useTable } from '@components/Table/hooks'
import { WhitelistRecordInterface } from '@src/interfaces/supportTool'
import { RowInterface } from '@src/interfaces/data'
import {
  whitelistActionColumn,
  whitelistCreatedByColumn,
  whitelistCycleColumn,
  whitelistEndDateColumn,
  whitelistReasonColumn,
  whitelistReviewerColumn,
  whitelistStartDateColumn,
  whitelistStatusColumn,
} from '@src/constants/columns/whitelist'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Statuses } from '@src/interfaces'
import { getWhitelist, revokeWhitelist } from '@src/api/whitelist'
import { Action, Box, MoreBar, TextWidget } from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import Table from '@src/components/TableV2/Table'

const ROW = (
  handleRevokeClick: (id: number) => void,
): RowInterface<WhitelistRecordInterface> => ({
  cells: [
    {
      ...whitelistReviewerColumn,
      width: 300,
    },
    {
      ...whitelistCycleColumn,
      width: 100,
    },
    {
      ...whitelistStartDateColumn,
      width: 100,
    },
    {
      ...whitelistEndDateColumn,
      width: 100,
    },
    {
      ...whitelistCreatedByColumn,
      width: 150,
    },
    {
      ...whitelistReasonColumn,
      width: 100,
    },
    {
      ...whitelistStatusColumn,
      width: 100,
    },
    {
      ...whitelistActionColumn,
      insert: ({ data }) => {
        if (data.status !== Statuses.active) {
          return ''
        }
        return (
          <Action
            onClick={e => {
              e.stopPropagation()
              handleRevokeClick(data.id)
            }}
          >
            Revoke
          </Action>
        )
      },
      width: 200,
    },
  ],
})

const Whitelist = () => {
  const table = useTable<WhitelistRecordInterface>({ getItems: getWhitelist() }, [], [])

  const handleNewRowClick = () => {
    navigateTo(pathToUrl(ROUTES.FORMS.WHITELIST.GENERAL))
  }

  const handleRowClick = (record: WhitelistRecordInterface) => {
    navigateTo(
      pathToUrl(ROUTES.FORMS.WHITELIST.GENERAL, {
        id: record.id,
      }),
    )
  }

  const handleRevokeClick = (id: number) => {
    revokeWhitelist(id).then(() => {
      table.refresh()
    })
  }

  return (
    <>
      <Box maxWidth={500} mb="s-16">
        <TextWidget>
          <TextWidget.Content>
            This tool is designed for performance team to accommodate reviewers who need
            to access performance review before or after the review cycle.
          </TextWidget.Content>
        </TextWidget>
      </Box>
      <Table.Widget>
        <Table.Widget.MoreBar>
          <MoreBar.Action useIcon={Plus} onClick={handleNewRowClick}>
            Add new
          </MoreBar.Action>
        </Table.Widget.MoreBar>
        <Table.Widget.Table>
          <AdjustableTable<WhitelistRecordInterface>
            name={TableNames.Whitelist}
            useWindowScroll
            row={ROW(handleRevokeClick)}
            {...table}
            emptyState={<EmptyTableRaw title="There are no whitelist records" />}
            onRowClick={handleRowClick}
          />
        </Table.Widget.Table>
      </Table.Widget>
    </>
  )
}

export default Whitelist
