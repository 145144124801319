import React, { useState } from 'react'
import format from 'date-fns/format'
import {
  Text,
  Avatar,
  Box,
  Item,
  ItemSkeleton,
  StatusWidget,
  VStack,
  chain,
  Token,
} from '@revolut/ui-kit'

import {
  getPayGroupTimelineTableRequests,
  useGetPayRollChangeDetails,
} from '@src/api/payrollV2'
import { useTable } from '@src/components/Table/hooks'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import Table from '@src/components/TableV2/Table'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { PayrollTimelineEventInterface } from '@src/interfaces/payrollV2'
import {
  payCycleTimelineCategoryColumn,
  payCycleTimelineChangesColumn,
  payCycleTimelineEffectiveDateTimeColumn,
  payCycleTimelineEmployeeNameColumn,
  payCycleTimelineFieldColumn,
} from '@src/constants/columns/payCycleTimeline'

import { CommonTabProps } from '../common'
import SideBar from '@src/components/SideBar/SideBar'
import { getAvatarUrl, getInitials } from '@src/utils/employees'
import { DetailsSidebarContent } from '../common/DetailsSidebarContent'

const getRow = (
  onClick: (id: number) => void,
): RowInterface<PayrollTimelineEventInterface> => ({
  linkToForm: data => onClick(data.content.id),
  cells: [
    {
      ...payCycleTimelineEmployeeNameColumn,
      width: 200,
    },
    {
      ...payCycleTimelineEffectiveDateTimeColumn,
      width: 120,
    },
    {
      ...payCycleTimelineCategoryColumn,
      width: 120,
    },
    {
      ...payCycleTimelineFieldColumn,
      width: 200,
    },
    {
      ...payCycleTimelineChangesColumn,
      width: 400,
    },
  ],
})

export const PayCycleTabChanges: React.FC<CommonTabProps> = ({ data }) => {
  const [selectedChangeId, setSelectedChangeId] = useState<number>()

  const {
    data: changeDetails,
    isLoading: isLoadingChangeDetails,
    isError: isChangeDetailsError,
  } = useGetPayRollChangeDetails(selectedChangeId)
  const selectedEmployee = changeDetails?.employee

  const groupId = data.pay_group.id
  const cycleStartDate = format(new Date(data.start_date), 'yyyy-MM-dd')
  const cycleEndDate = format(new Date(data.end_date), 'yyyy-MM-dd')

  const table = useTable<PayrollTimelineEventInterface>(
    getPayGroupTimelineTableRequests(groupId),
    [
      {
        columnName: 'from_date',
        filters: [{ id: cycleStartDate, name: cycleStartDate }],
      },
      { columnName: 'to_date', filters: [{ id: cycleEndDate, name: cycleEndDate }] },
    ],
  )

  return (
    <>
      <Table.Widget>
        <Table.Widget.Table>
          <AdjustableTable
            name={TableNames.PayCycleEmployeeReports}
            row={getRow(id => setSelectedChangeId(id))}
            useWindowScroll
            dataType="change"
            emptyState={
              <Box py="s-32">
                <StatusWidget>
                  <StatusWidget.Image
                    image={{
                      default: 'https://assets.revolut.com/assets/3d-images-v2/3D028.png',
                      '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D028@2x.png',
                      '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D028@3x.png',
                    }}
                  />
                  <StatusWidget.Title>Nothing found</StatusWidget.Title>
                  <StatusWidget.Description>
                    No employee reports for this cycle
                  </StatusWidget.Description>
                </StatusWidget>
              </Box>
            }
            {...table}
          />
        </Table.Widget.Table>
      </Table.Widget>
      <SideBar
        title=""
        variant="wide"
        sideProps={{ resizable: true }}
        isOpen={!!selectedChangeId}
        onClose={() => setSelectedChangeId(undefined)}
      >
        {isLoadingChangeDetails ? (
          <VStack space="s-16">
            <ItemSkeleton />
            <ItemSkeleton />
            <ItemSkeleton />
          </VStack>
        ) : (
          <VStack space="s-20">
            {selectedEmployee ? (
              <Item>
                <Item.Avatar>
                  <Avatar
                    image={getAvatarUrl(selectedEmployee.avatar)}
                    label={getInitials(selectedEmployee.full_name || '')}
                  />
                </Item.Avatar>
                <Item.Content>
                  <Item.Title>
                    <Text>{selectedEmployee.full_name}</Text>
                  </Item.Title>
                  {!!(selectedEmployee.job_title || selectedEmployee.seniority) && (
                    <Item.Description>
                      <Text color={Token.color.greyTone50}>
                        {chain(
                          selectedEmployee.job_title,
                          selectedEmployee.seniority?.name,
                        )}
                      </Text>
                    </Item.Description>
                  )}
                </Item.Content>
              </Item>
            ) : (
              <ItemSkeleton />
            )}
            <DetailsSidebarContent
              isLoading={isLoadingChangeDetails}
              isError={isChangeDetailsError}
              timelineChanges={changeDetails ? [changeDetails] : undefined}
              onClose={() => setSelectedChangeId(undefined)}
            />
          </VStack>
        )}
      </SideBar>
    </>
  )
}
