import React from 'react'
import isString from 'lodash/isString'
import {
  Avatar,
  Flex,
  Skeleton,
  AvatarSize,
  Box,
  AvatarBadgeProps,
  Token,
  Text,
  getTextStyle,
  TextStyle,
  BoxProps,
  Ellipsis,
} from '@revolut/ui-kit'
import TableCellLink from '@components/TableCellLink/TableCellLink'
import { pathToUrl, pathToUrlWithBaseUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { IdStatuses, AvatarType } from '@src/interfaces/employees'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import styled from 'styled-components'
import { css } from '@styled-system/css'
import { Link } from 'react-router-dom'
import Tooltip, { placement } from '@components/Tooltip/Tooltip'
import { getInitials, getAvatarUrl } from '@src/utils/employees'

const TooltipLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  justify-self: start;
`

const TooltipAvatar = styled(Tooltip)`
  justify-content: flex-start;
  width: 24px;
`

const AvatarAsTextFlexContainer = styled(Flex)(
  css({
    pointerEvents: 'none',
    color: Token.color.foreground,
    ...getTextStyle(TextStyle.PRIMARY),
  }),
)

export interface UserWithAvatarProps extends Omit<BoxProps, 'id'> {
  avatar?: AvatarType
  name?: string
  id?: string | number
  full_name?: string
  status?: IdStatuses
  isLoading?: boolean
  compact?: boolean
  tooltipPlacement?: placement
  /** Disables the link to the employee's profile */
  asText?: boolean
  size?: AvatarSize
  usePathWithBaseUrl?: boolean
  disableLink?: boolean // asText does the same but also changes styles which is not always needed
  badgeProps?: AvatarBadgeProps
  avatarUrlFunc?: (id: string) => string
}

const UserWithAvatar = ({
  id,
  name,
  full_name,
  avatar,
  isLoading,
  status,
  compact,
  tooltipPlacement = 'top',
  asText,
  size,
  usePathWithBaseUrl,
  disableLink,
  badgeProps,
  avatarUrlFunc,
  ...flexProps
}: UserWithAvatarProps) => {
  if (isLoading) {
    return (
      <Flex alignItems="center" {...flexProps}>
        <Skeleton height={24} width={150} />
      </Flex>
    )
  }

  const pathToUrlHandler = usePathWithBaseUrl ? pathToUrlWithBaseUrl : pathToUrl
  const linkTarget = usePathWithBaseUrl ? '_blank' : undefined

  const nameString = full_name || name

  if (!nameString || !isString(nameString)) {
    return <>-</>
  }

  const initials = getInitials(nameString)

  const isTerminated = status === IdStatuses.terminated
  const displayName = nameString + (isTerminated ? ' · Deactivated' : '')

  const nameJSX = (
    <Text color={isTerminated ? Token.color.greyTone50 : undefined}>
      <Ellipsis>{displayName}</Ellipsis>
    </Text>
  )
  const avatarJSX = (
    <Box mr={size && size >= 40 ? 's-16' : 's-8'}>
      <Avatar
        size={size || 24}
        image={getAvatarUrl(avatar)}
        opacity={isTerminated ? 0.5 : 1}
      >
        {!avatar && initials}
        {badgeProps && <Avatar.Badge {...badgeProps} />}
      </Avatar>
    </Box>
  )
  const Container = asText ? AvatarAsTextFlexContainer : Flex
  const link = getLocationDescriptor(
    id && avatarUrlFunc
      ? avatarUrlFunc(String(id))
      : pathToUrlHandler(ROUTES.FORMS.EMPLOYEE.PROFILE, { id }),
  )

  return (
    <Container alignItems="center" data-testid={`avatar-${initials}`} {...flexProps}>
      {compact ? (
        <TooltipAvatar placement={tooltipPlacement} text={displayName}>
          <TooltipLink to={link} target={linkTarget}>
            {avatarJSX}
          </TooltipLink>
        </TooltipAvatar>
      ) : (
        <>
          {avatarJSX}
          {id && !disableLink ? (
            <TableCellLink to={link} target={linkTarget} style={{ overflow: 'hidden' }}>
              {nameJSX}
            </TableCellLink>
          ) : (
            nameJSX
          )}
        </>
      )}
    </Container>
  )
}

export default UserWithAvatar
