import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import { EmployeeInterface } from '@src/interfaces/employees'
import { EmployeePageHeader } from '@src/pages/EmployeeProfile/Layout/common/EmployeePageHeader'
import { CompensationParams } from '@src/pages/EmployeeProfile/Layout/common/types'
import { EquityView } from './Pages/Equity'

type Props = {
  compensationParams: CompensationParams
  data: EmployeeInterface
}
export const CompensationV2Pages = ({ compensationParams, ...props }: Props) => {
  const tabs = [
    {
      title: 'Your equity',
      path: ROUTES.FORMS.EMPLOYEE.EQUITY_OVERVIEW,
      component: EquityView,
    },
  ]

  return (
    <Switch>
      {tabs.map(tab => {
        return (
          <Route exact key={tab.path} path={tab.path}>
            <EmployeePageHeader title={tab.title} data={props.data} />
            <tab.component compensationParams={compensationParams} {...props} />
          </Route>
        )
      })}
    </Switch>
  )
}
