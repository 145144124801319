import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  Box,
  Cell,
  chain,
  createChain,
  ErrorWidget,
  Flex,
  Group,
  HStack,
  MoreBar,
  Widget,
  Text,
  TransitionCollapse,
  Action,
  Popup,
  List,
  Avatar,
  TextButton,
  Link,
  Token,
} from '@revolut/ui-kit'
import {
  ArrowThinRight,
  BarChart,
  Chat,
  Check,
  InfoOutline,
  Plus,
  SandWatch,
} from '@revolut/icons'
import Lock from '@src/assets/3D/Lock@2x.png'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import format from 'date-fns/format'
import isPast from 'date-fns/isPast'

import {
  EmployeeInterface,
  EmployeeStats,
  IdStatuses,
  InternalOrExternalEmployee,
} from '@src/interfaces/employees'
import { PageBody } from '@components/Page/PageBody'
import AdjustableTable from '@components/Table/AdjustableTable'
import { KpiInterface } from '@src/interfaces/kpis'
import {
  kpiCalibratedPerformanceColumn,
  kpiCurrentValueColumn,
  kpiGenericNameColumn,
  kpiInitialValueColumn,
  kpiPerformanceColumn,
  kpiTargetColumn,
  kpiUnitColumn,
  kpiWeightColumn,
} from '@src/constants/columns/kpi'
import { ColoredPercent } from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import { pathToUrl } from '@src/utils/router'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import {
  roadmapDueDate,
  roadmapGenericNameColumn,
  roadmapPriorityColumn,
  roadmapProgressColumn,
  roadmapStatusColumn,
} from '@src/constants/columns/roadmap'
import { RoadmapInterface } from '@src/interfaces/roadmaps'
import {
  performanceFeedbackColumn,
  performanceGradeColumn,
  performanceTimelineNameColumn,
  performanceTimelineSeniorityColumn,
  performanceTimelineSpecialisationColumn,
} from '@src/constants/columns/performance'
import Stat from '@components/Stat/Stat'
import TalentHeader from '../../../Forms/CommonTalentTab/TalentHeader'
import { getTalentTypes, TalentType } from '@src/interfaces/talent/talent'
import { useTalentTable } from '../../../Forms/CommonTalentTab/Talent'
import { selectUser } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { BudgetInterface } from '@src/interfaces/budgets'
import { BudgetStats, RowInterface, Stats } from '@src/interfaces/data'
import {
  budgetAnnualExpenseColumn,
  budgetEmployeeNameColumn,
  budgetPerfLabelReviewColumn,
  budgetRoleColumn,
  budgetSeniorityColumn,
} from '@src/constants/columns/budgets'
import {
  getContractorFields,
  getEmployeeFields,
  getEmployeePerformanceGraph,
  getEmployeeProfileCommentsAPI,
  getKpiPerformanceGraph,
} from '@src/api/employees'
import { FormPreview } from '@components/FormPreview/FormPreview'
import {
  CycleFilter,
  CycleFilterType,
} from '@components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilter'
import {
  getPerformanceTimelineStats,
  useGetPerformanceSelector,
} from '@src/api/performance'
import {
  FinalGrade,
  PerformanceSelector,
  PerformanceTimelineInterface,
  PerformanceTimelineStats,
  ReviewCategory,
} from '@src/interfaces/performance'
import { FilterSelectType } from '@components/Inputs/Filters/FilterSelect/FilterSelect'
import { useTable, useTableReturnType } from '@components/Table/hooks'
import { EntityTypes, Queries, selectorKeys } from '@src/constants/api'
import { getAuditTickets } from '@src/api/audit'
import { AuditInterface } from '@src/interfaces/audit'
import FindingsSummary, {
  findingsSummaryFilters,
  findingsSummarySorting,
} from '@src/features/Summary/FindingsSummary'
import GraphIconChart from '@components/Charts/GraphIconChart/GraphIconChart'
import { getFilterBy, sortBy, getStatsData } from '../ProfileSummary/components/Hiring'
import {
  requisitionCandidatesColumn,
  requisitionHeadcountColumn,
  requisitionPriorityColumn,
  createRequisitionTitleColumn,
} from '@src/constants/columns/requisition'
import { seniorityNameRequisitionsColumn } from '@src/constants/columns/seniority'
import { locationNameRequisitionColumn } from '@src/constants/columns/location'
import { Priority } from '@src/interfaces/requisitions'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'
import CommentsSection from '@src/features/Comments/CommentsSection'
import EmployeePerformanceChart, {
  Chart,
} from '@components/Charts/EmployeePerformanceChart/EmployeePerformanceChart'
import {
  SummarySubheader,
  SummaryTableContainer,
  summaryTableProps,
} from '@src/features/Summary/common'
import { withoutFilterAndSort } from '@src/utils/table'
import LoadSectionInView from '@components/LoadSectionInView/LoadSectionInView'
import SettingsButtons from '@src/features/SettingsButtons'
import { TableNames } from '@src/constants/table'
import {
  useGetEmployeeSettings,
  useGetOrganisationSettings,
  useGlobalSettings,
} from '@src/api/settings'
import { useGetAllFeedbackList } from '@src/api/anytimeFeedback'
import { AnytimeFeedbackCard } from '@components/AnytimeFeedback/AnytimeFeedbackCard'
import {
  AnytimeFeedbackSidebar,
  tabsCategory,
} from '@components/AnytimeFeedback/AnytimeFeedbackSidebar'
import { FeedbackCategory } from '@src/interfaces/anytimeFeedback'
import { useRequisitionTable } from '@src/features/CommonRequisitionTable/CommonRequisitionTable'
import { ChartTableTabs } from '@src/features/TabBarSwitcher/useChartTableSwitcher'
import { useResignationPermissions } from '@src/pages/Forms/EmployeeResignation/hooks'
import OverallFeedback from '@src/pages/Forms/EmployeePerformance/OverallFeedback'
import { useGetPromotionProgression } from '@src/api/promotions'
import { Statuses } from '@src/interfaces'
import { useQuery } from '@src/utils/queryParamsHooks'
import Tooltip from '@components/Tooltip/Tooltip'
import { PROGRESSION_POLICY_PLAYBOOK_LINK } from '@src/constants/externalLinks'
import {
  ChangeRequestsPendingWidget,
  useEmployeesOverdueInboxStats,
  useSectionHeader,
} from '../ProfileSummary/common'
import { EmployeeBadges } from '../ProfileSummary/components/EmployeeBadges'
import { checkIsProbationPeriod } from '../Performance/Common/utils'
import PerformanceSummarySection from '../Performance/PerformanceSection'
import { SidebarDetails } from '../Performance/PerformanceHistory/PerformanceHistory'
import SummarySidebar from '../Performance/Summary/SummarySidebar'
import { getRoadmapFilters } from '../Roadmap'
import { readOnlyRows } from '../Roadmap/common'
import {
  useGetLatestGrade,
  ToggleSecondaryTabs,
  usePerformanceChartData,
  usePerformanceTimelineTable,
  useTableSwitchers,
} from '../Performance/PerformanceHistory/hooks'
import { ROW as personalKPIRow, usePersonalKPITable } from '../KPI/PersonalKPI'
import { onKPITableRowClick } from '../KPI/utils'
import { EmployeeCyclesButtons } from './EmployeeCyclesButtons'
import { PerformanceTimeline } from './PerformanceTimeline'
import { roadmapsRequests } from '@src/api/roadmaps'
import { commonRoadmapSorting } from '@src/features/Roadmaps/table'
import { useIsNewLayout } from '@src/pages/EmployeeProfile/Layout/helpers'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { canViewProgressionComponent } from '@src/pages/EmployeeProfile/Layout/Performance/utils'
import { useGetSelectors } from '@src/api/selectors'

export const kpiRow = {
  ...personalKPIRow,
  cells: withoutFilterAndSort([
    {
      ...kpiGenericNameColumn,
      title: 'Name',
    },
    {
      ...kpiWeightColumn,
      width: 80,
    },
    {
      ...kpiPerformanceColumn,
      width: 100,
    },
    {
      ...kpiCalibratedPerformanceColumn,
      width: 130,
    },
    {
      ...kpiInitialValueColumn,
      width: 70,
    },
    {
      ...kpiCurrentValueColumn,
      width: 70,
    },
    {
      ...kpiTargetColumn,
      width: 70,
    },
    {
      ...kpiUnitColumn,
      width: 70,
    },
  ]),
}

const roadmapROW = {
  ...readOnlyRows,
  noChildrenRequest: true,
  isNotNested: true,
  cells: withoutFilterAndSort([
    {
      ...roadmapGenericNameColumn,
      width: 160,
    },
    {
      ...roadmapStatusColumn,
      width: 80,
    },
    {
      ...roadmapDueDate,
      width: 80,
    },
    {
      ...roadmapPriorityColumn,
      width: 80,
    },
    {
      ...roadmapProgressColumn,
      width: 100,
    },
  ]),
}

const performanceTimelineROW = (
  onMessageClick: (data: PerformanceTimelineInterface) => void,
  cycleId?: number | string,
): RowInterface<PerformanceTimelineInterface> => ({
  noChildrenRequest: true,
  highlight: data => {
    if (data.cycle.id === cycleId) {
      return Token.color.actionBackground
    }
    return ''
  },
  cells: [
    {
      ...performanceTimelineNameColumn,
      width: 100,
    },
    {
      ...performanceGradeColumn,
      width: 100,
    },
    {
      ...performanceTimelineSeniorityColumn,
      width: 140,
    },
    {
      ...performanceTimelineSpecialisationColumn,
      width: 100,
    },
    {
      ...performanceFeedbackColumn(onMessageClick),
      width: 100,
    },
  ],
})

const talentROW = {
  cells: withoutFilterAndSort([
    {
      ...budgetEmployeeNameColumn,
      width: 100,
    },
    {
      ...budgetRoleColumn,
      width: 100,
    },
    {
      ...budgetSeniorityColumn,
      width: 80,
    },
    {
      ...budgetAnnualExpenseColumn,
      masked: true,
      width: 100,
    },
    {
      ...budgetPerfLabelReviewColumn,
      masked: true,
      width: 140,
    },
  ]),
}

const hiringROW = {
  cells: withoutFilterAndSort([
    {
      ...createRequisitionTitleColumn(false),
      width: 100,
    },
    {
      ...seniorityNameRequisitionsColumn,
      width: 70,
    },
    {
      ...locationNameRequisitionColumn,
      width: 70,
    },
    {
      ...requisitionHeadcountColumn,
      width: 70,
    },
    {
      ...requisitionPriorityColumn,
      width: 70,
    },
    {
      ...requisitionCandidatesColumn,
      width: 70,
    },
  ]),
}

type HiddenCells = Partial<Record<keyof BudgetInterface, boolean>>

interface CommonProps {
  data: EmployeeInterface
}

interface PerformanceSummaryProps extends CommonProps {
  stats?: EmployeeStats
  refreshData?: () => void
}

export const AccessError = () => (
  <ErrorWidget>
    <ErrorWidget.Image src={Lock} />
    <ErrorWidget.Title>You don’t have access to this section</ErrorWidget.Title>
    <ErrorWidget.Description>
      Only the employee’s managers and performance team can view this section
    </ErrorWidget.Description>
  </ErrorWidget>
)

export const PerformanceSummary = ({
  data,
  stats,
  refreshData,
}: PerformanceSummaryProps) => {
  const { field_options } = data
  const kpiTable = usePersonalKPITable(data, undefined, [
    {
      filters: [{ name: `0`, id: 0 }],
      columnName: 'review_cycle__offset',
      nonResettable: true,
    },
  ])

  const roadmapTable = useTable<RoadmapInterface, Stats>(
    roadmapsRequests,
    getRoadmapFilters(data, 'personal'),
    commonRoadmapSorting,
  )

  const talentTableRef = useRef<TalentTable>()

  const { data: organisationSettings } = useGetOrganisationSettings()

  const {
    settings: { resignations_enabled },
  } = useGlobalSettings()
  const { canAdd } = useResignationPermissions()
  const canCreateResignation = resignations_enabled && canAdd

  const canChangeEmployeeStatus = field_options?.permissions?.includes(
    PermissionTypes.ChangeEmployeeStatus,
  )

  const shouldRenderCyclesButtons = canChangeEmployeeStatus || canCreateResignation

  const setCommentsSidebarOpen = useRef((_: boolean) => {})

  const params = useParams<{ id?: string }>()

  const [timelineStats, setTimelineStats] = useState<PerformanceTimelineStats | null>()
  const [feedbackSidebarCategory, setFeedbackSidebarCategory] =
    useState<tabsCategory | null>(null)

  const overdueItems = useEmployeesOverdueInboxStats(data)?.stats?.total

  const canViewOverdueItems = !!data?.field_options?.permissions?.includes(
    PermissionTypes.ViewInbox,
  )
  const canViewFindings =
    !!organisationSettings?.findings?.enabled &&
    !!data?.field_options?.permissions?.includes(PermissionTypes.ViewEmployeeAudits)
  const canViewComments = !!data?.field_options?.permissions?.includes(
    PermissionTypes.AccessCommentsEmployees,
  )

  const canViewPerformanceHistoryTab = !!data?.field_options?.permissions?.includes(
    PermissionTypes.ViewPerformanceHistoryTab,
  )
  const isActive = data.status.id === IdStatuses.active

  const user = useSelector(selectUser)
  const isCurrentUserPage = String(user.id) === params.id
  const isManager =
    data.line_manager?.id === user.id || data.quality_control?.id === user.id

  const commentsApi = getEmployeeProfileCommentsAPI(data.id)

  const commentsData = commentsApi.useGetComments(false, !canViewComments).data

  const showRoadmapDetails = useCallback((row: RoadmapInterface) => {
    if (row.epic_url) {
      window.open(row.epic_url, '_blank')
    }
  }, [])

  const PageWrapper = useIsNewLayout() ? React.Fragment : PageBody
  const getLatestGrade = useGetLatestGrade()
  useEffect(() => {
    if (isManager || canViewPerformanceHistoryTab) {
      getPerformanceTimelineStats(data.id)
        .then(response => setTimelineStats(response.data))
        .catch(() => setTimelineStats(null))
    }
  }, [])

  return (
    <PageWrapper>
      <Box mb="s-16">
        <Widget p="s-16">
          <Flex flexDirection="column">
            <Flex flexDirection="row">
              <CycleFilter
                type={CycleFilterType.NewUI}
                onFilterChange={() => {}}
                columnName="review_cycle__offset"
                filter={[
                  {
                    filters: [{ name: `0`, id: 0 }],
                    columnName: 'review_cycle__offset',
                    nonResettable: true,
                  },
                ]}
                filterInputType={FilterSelectType.SingleSelect}
                disabled
                label="Current cycle"
              />
              {timelineStats == null ? null : (
                <Stat
                  label={`Latest grade${
                    timelineStats?.cycle?.name ? ` (${timelineStats.cycle.name})` : ''
                  }`}
                  val={
                    <Flex alignItems="center">
                      <Box mr="s-8">{getLatestGrade(timelineStats)}</Box>

                      <EmployeePerformanceChart
                        id={data.id}
                        fetchKpi={getEmployeePerformanceGraph}
                      >
                        <BarChart cursor="pointer" size={16} color="grey-tone-20" />
                      </EmployeePerformanceChart>
                    </Flex>
                  }
                  ml="s-32"
                />
              )}
              {canViewOverdueItems ? (
                <Stat
                  label="Items overdue"
                  val={overdueItems}
                  ml="s-32"
                  color={
                    overdueItems != null && overdueItems > 0
                      ? Token.color.red
                      : Token.color.foreground
                  }
                />
              ) : null}
            </Flex>
            <SettingsButtons mt="s-16">
              <MoreBar.Action
                onClick={() =>
                  navigateTo(
                    pathToUrl(
                      isCurrentUserPage
                        ? ROUTES.FORMS.EMPLOYEE.FEEDBACK.ONE_TO_ONE
                        : ROUTES.FORMS.EMPLOYEE.FEEDBACK.ANYTIME,
                      {
                        userId: params.id,
                      },
                    ),
                  )
                }
                useIcon={Plus}
              >
                Add feedback
              </MoreBar.Action>
              {canViewComments ? (
                <MoreBar.Action
                  onClick={() => setCommentsSidebarOpen.current(true)}
                  useIcon={Chat}
                >
                  {chain('Notes', commentsData?.count)}
                </MoreBar.Action>
              ) : null}
              {canViewOverdueItems ? (
                <MoreBar.Action
                  onClick={() =>
                    navigateTo(
                      pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.ACTION_ITEMS, params),
                    )
                  }
                >
                  View overdue items
                </MoreBar.Action>
              ) : null}
              {shouldRenderCyclesButtons && (
                <EmployeeCyclesButtons data={data} refreshData={refreshData} />
              )}
            </SettingsButtons>
          </Flex>
          {isActive && (
            <PerformanceTimeline
              employeeId={data.id}
              data={data}
              onViewNotesClick={() => setFeedbackSidebarCategory(tabsCategory.OneToOne)}
            />
          )}
        </Widget>
      </Box>

      {canViewPerformanceHistoryTab && (
        <PerformanceProgressionAndTimelineSections data={data} />
      )}
      <FeedbackSection
        data={data}
        feedbackSidebarCategory={feedbackSidebarCategory}
        setFeedbackSidebarCategory={setFeedbackSidebarCategory}
      />
      <ProfileSection data={data} />
      <EmployeeBadges id={data.id} />

      <Box mt="s-16">
        <SummarySubheader
          formUrl={pathToUrl(
            ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.KPI.PERSONAL,
            params,
          )}
        >
          <Flex alignItems="center">
            KPIs
            {stats ? (
              <>
                {' '}
                · {<ColoredPercent percent={stats.kpi_performance_percent * 100} />}
                {
                  <GraphIconChart
                    id={data.id}
                    vertical="right"
                    fetchData={getKpiPerformanceGraph}
                  >
                    <Flex ml="s-8" mt="-s-2">
                      <BarChart cursor="pointer" size={16} color="grey-tone-20" />
                    </Flex>
                  </GraphIconChart>
                }
              </>
            ) : null}
          </Flex>
        </SummarySubheader>

        <SummaryTableContainer>
          <AdjustableTable<KpiInterface>
            name={TableNames.EmployeeKPIs}
            dataType="KPI"
            row={kpiRow}
            {...kpiTable}
            noDataMessage="Please add your personal KPIs."
            onRowClick={onKPITableRowClick}
            {...summaryTableProps}
            hideCountAndButtonSection
          />
        </SummaryTableContainer>
      </Box>

      <Box mt="s-16">
        <SummarySubheader formUrl={pathToUrl(ROUTES.FORMS.EMPLOYEE.ROADMAP, params)}>
          <HStack>
            Roadmap
            {stats ? (
              <> · {<ColoredPercent percent={stats.roadmap_progress_percent * 100} />}</>
            ) : null}
          </HStack>
        </SummarySubheader>
        <SummaryTableContainer>
          <AdjustableTable<RoadmapInterface>
            name={TableNames.EmployeeRoadmapItems}
            row={roadmapROW}
            onRowClick={showRoadmapDetails}
            {...roadmapTable}
            noDataMessage="All your items will appear here."
            {...summaryTableProps}
            hideCountAndButtonSection
          />
        </SummaryTableContainer>
      </Box>

      <Box mt="s-16">
        <SummarySubheader
          formUrl={pathToUrl(
            ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.TALENT.DIRECT_REPORTS,
            params,
          )}
        >
          Talent
        </SummarySubheader>
        <LoadSectionInView>
          <TalentSection
            data={data}
            tableRef={table => {
              talentTableRef.current = table
            }}
          />
        </LoadSectionInView>
      </Box>

      <Box mt="s-16">
        <SummarySubheader formUrl={pathToUrl(ROUTES.FORMS.EMPLOYEE.HIRING, params)}>
          Hiring
        </SummarySubheader>
        <LoadSectionInView>
          <HiringSection data={data} />
        </LoadSectionInView>
      </Box>

      {canViewFindings && <FindingsSection data={data} />}

      {canViewComments && (
        <Box mt="s-16">
          <CommentsSection
            disableTodolistFeature
            api={commentsApi}
            title="Notes"
            setSidebarOpen={setCommentsSidebarOpen}
          />
        </Box>
      )}
    </PageWrapper>
  )
}

const FeedbackSection = ({
  data,
  feedbackSidebarCategory,
  setFeedbackSidebarCategory,
}: {
  data: EmployeeInterface
  feedbackSidebarCategory: tabsCategory | null
  setFeedbackSidebarCategory: (category: tabsCategory | null) => void
}) => {
  const params = useParams<{ id?: string }>()
  const user = useSelector(selectUser)
  const isCurrentUserPage = String(user.id) === params.id
  const { data: feedbackList, refetch: refetchFeedbackList } = useGetAllFeedbackList(
    data.id,
  )
  const filteredFeedback = feedbackList?.results?.filter(
    item => item.category === FeedbackCategory.Anytime,
  )

  return (
    <>
      {feedbackList && filteredFeedback?.[0] && (
        <>
          <Flex alignItems="center" justifyContent="space-between" mt="s-16" mb="s-16">
            <Text use="p" fontWeight={500} color="grey-tone-50">
              Latest feedback
            </Text>
            <Flex alignItems="center">
              {!isCurrentUserPage ? (
                createChain(
                  <Text fontWeight="500" color="blue" px="s-4">
                    ·
                  </Text>,
                )(
                  <Action
                    onClick={() =>
                      navigateTo(
                        pathToUrl(ROUTES.FORMS.EMPLOYEE.FEEDBACK.ANYTIME, {
                          userId: params.id,
                        }),
                      )
                    }
                  >
                    Add feedback
                  </Action>,
                  <Action
                    onClick={() => setFeedbackSidebarCategory(tabsCategory.Anytime)}
                  >
                    {`See all (${feedbackList?.results?.length})`}
                  </Action>,
                )
              ) : (
                <Action onClick={() => setFeedbackSidebarCategory(tabsCategory.Anytime)}>
                  {`See all (${feedbackList?.results?.length})`}
                </Action>
              )}
            </Flex>
          </Flex>
          <AnytimeFeedbackCard
            feedback={filteredFeedback[0]}
            refreshFeedbackCard={refetchFeedbackList}
            employeeData={data}
          />
          <AnytimeFeedbackSidebar
            isOpen={!!feedbackSidebarCategory}
            handleClose={() => setFeedbackSidebarCategory(null)}
            feedbackList={feedbackList?.results}
            refetchFeedbackList={refetchFeedbackList}
            employeeId={String(data.id)}
            openTab={feedbackSidebarCategory || undefined}
          />
        </>
      )}
    </>
  )
}

export const PerfomanceSummarySection = ({ data }: CommonProps) => {
  const { data: performanceSelectorData } = useGetPerformanceSelector(data.id)

  const [isProbation, setIsProbation] = useState(false)
  const [selectedPeriod, setSelectedPeriod] = useState<PerformanceSelector>()

  const isProbationPeriod = checkIsProbationPeriod(data)

  useEffect(() => {
    if (performanceSelectorData) {
      if (isProbationPeriod) {
        const probationCycle = performanceSelectorData.find(
          p => p.category === ReviewCycleCategory.Probation,
        )
        if (probationCycle) {
          const startDate = format(new Date(probationCycle.start_date_time), 'd MMM')
          const endDate = format(new Date(probationCycle.end_date_time), 'd MMM')

          setIsProbation(true)
          setSelectedPeriod({
            ...probationCycle,
            name: `${probationCycle.name} · ${startDate} - ${endDate}`,
          })
          return
        }
      }

      setSelectedPeriod(
        // Select the most recent cycle with published data
        performanceSelectorData
          .filter(
            p =>
              p.managers_publishing_day != null &&
              isPast(new Date(p.managers_publishing_day)),
          )
          .sort(
            (a, b) =>
              new Date(b.managers_publishing_day!).getTime() -
              new Date(a.managers_publishing_day!).getTime(),
          )[0],
      )
    }
  }, [performanceSelectorData])

  return (
    <PerformanceSummarySection
      period={selectedPeriod}
      category={isProbation ? ReviewCategory.Probation : ReviewCategory.Performance}
      employeeId={data.id}
      employeeSeniorityId={data.seniority?.id}
    />
  )
}

interface ProgressionSectionProps extends CommonProps {
  sticky?: boolean
  renderHeader?: boolean
  withBorder?: boolean
  noError?: boolean
  cycleId?: number | string
}
export const ProgressionSection = ({
  data,
  sticky = false,
  renderHeader = true,
  withBorder = false,
  noError = false,
  cycleId,
}: ProgressionSectionProps) => {
  const { data: progression, error } = useGetPromotionProgression(data.id, cycleId)

  const { data: gradesOptions } = useGetSelectors<{ id: FinalGrade; name: string }>(
    selectorKeys.grades,
  )

  const strongGradeOption = gradesOptions?.find(grade => grade.id === FinalGrade.Strong)
  const isOldGrades: boolean = strongGradeOption?.name === 'Strong'

  const minProgressionGradeLabel = gradesOptions?.find(grade =>
    isOldGrades ? grade.id === FinalGrade.Strong : grade.id === FinalGrade.AveragePlus,
  )?.name
  const gradesChain = createChain(' / ')(
    minProgressionGradeLabel,
    isOldGrades ? undefined : 'Exceeding',
    'Exceptional',
  )

  if (error && noError) {
    return null
  }

  if (error) {
    return (
      <Box mt="s-16">
        <SummarySubheader>Progression</SummarySubheader>
        <AccessError />
      </Box>
    )
  }

  const showProgression =
    progression?.current_seniority &&
    progression?.target_seniority &&
    progression?.eligibility_criteria &&
    progression?.eligibility_criteria_result

  const canViewProgression =
    data.status.id === IdStatuses.active &&
    data.specialisation_seniority_sublevel?.id !== undefined

  if (!showProgression || !canViewProgression) {
    return null
  }

  const cyclesRequired = progression?.eligibility_criteria?.cycles_in_role_and_seniority
  const cyclesCurrent =
    progression?.eligibility_criteria_result?.cycles_in_role_and_seniority
  const cyclesEligible =
    cyclesCurrent && cyclesRequired && cyclesCurrent >= cyclesRequired

  const gradesRequired =
    progression?.eligibility_criteria?.strong_plus_grades_in_recent_cycles || 0
  const gradesCurrent =
    progression?.eligibility_criteria_result?.strong_plus_grades_in_recent_cycles || 0
  const gradesEligible = gradesCurrent >= gradesRequired

  const getBadge = (eligible: boolean) => {
    return eligible ? (
      <Avatar.Badge bg={Token.color.green} useIcon={Check} size={16} />
    ) : (
      <Avatar.Badge bg={Token.color.orange} useIcon={SandWatch} size={16} />
    )
  }

  return (
    <Box
      mt={sticky ? 0 : 's-16'}
      border={withBorder ? `1px solid ${Token.color.greyTone10}` : undefined}
      borderRadius={16}
    >
      {renderHeader && <SummarySubheader>Progression</SummarySubheader>}
      <Widget p="s-16">
        <Flex alignItems="center" mb="s-8">
          <Text variant="h6" fontWeight={500}>
            {progression.current_seniority?.name}
          </Text>
          <Box px="s-4">
            <ArrowThinRight color="grey-tone-20" size={16} />
          </Box>
          <Text variant="h6" fontWeight={500}>
            {progression.target_seniority?.name}
          </Text>
        </Flex>
        <List variant="compact">
          <List.Item useIcon={getBadge(Boolean(cyclesEligible))}>
            {chain(
              'Review cycles at your current level',
              `${cyclesCurrent}/${cyclesRequired}`,
            )}
          </List.Item>
          <List.Item useIcon={getBadge(Boolean(gradesEligible))}>
            {chain(
              `${minProgressionGradeLabel}+ grades at your current level (up to last 4 cycles)`,
              `${gradesCurrent}/${gradesRequired}`,
            )}
          </List.Item>
        </List>
        {progression.eligibility_criteria_result.progression_cycle && (
          <Flex justifyContent="space-between" alignItems="center" mt="s-16">
            <Box maxWidth="95%">
              <Text variant="h6" fontWeight={400}>
                On track for {progression.eligibility_criteria_result.progression_cycle}{' '}
              </Text>
            </Box>
            <Tooltip
              placement="top"
              body={
                <Flex p="s-16" flexDirection="column" alignItems="center">
                  <Text use="div" color="background" whiteSpace="nowrap">
                    Receiving a {gradesChain} grade will keep you on track for the next
                    seniority level.
                  </Text>
                  <Text use="div" color="background">
                    Note: This is a preliminary assessment only and the final grades not
                    being {gradesChain} and / or a change of roles may impact progression
                    eligibility.
                  </Text>
                  <HideIfCommercial>
                    <Text use="div" color="background" whiteSpace="nowrap">
                      For more information, please refer to the{' '}
                      <Link
                        target="_blank"
                        href={PROGRESSION_POLICY_PLAYBOOK_LINK}
                        color="background"
                        textDecoration="underline"
                      >
                        policy playbook
                      </Link>
                      .
                    </Text>
                  </HideIfCommercial>
                </Flex>
              }
            >
              <InfoOutline size={16} color="grey-tone-50" cursor="pointer" />
            </Tooltip>
          </Flex>
        )}
      </Widget>
    </Box>
  )
}

export const PerformanceSection = ({
  data,
  renderHeader = true,
  selectedCycle,
  onRowClick,
}: {
  data: EmployeeInterface
  renderHeader?: boolean
  selectedCycle?: PerformanceSelector
  onRowClick?: (rowData: PerformanceTimelineInterface) => void
}) => {
  const performanceTimelineTable = usePerformanceTimelineTable(data)
  const { mappedChartData } = usePerformanceChartData(data)
  const { data: performanceSettings } = useGetPerformanceSettings()
  const { tabBar, currentTab, secondaryTabBar, currentSecondaryTab } =
    useTableSwitchers(performanceSettings)

  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [popupDetails, setPopupDetails] = useState<PerformanceTimelineInterface>()

  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [sidebarDetails, setSidebarDetails] = useState<SidebarDetails>()
  const { changeQueryParam, deleteQueryParam } = useQuery()
  const [rowsExpanded, setRowsExpanded] = useState(false)

  const getChartData = (tab?: ToggleSecondaryTabs) => {
    switch (tab) {
      case ToggleSecondaryTabs.Deliverables:
        return mappedChartData.deliverables
      case ToggleSecondaryTabs.Skills:
        return mappedChartData.skills
      default:
        return mappedChartData.grades
    }
  }

  const onMessageClick = (notesData: PerformanceTimelineInterface) => {
    setPopupDetails(notesData)
    setIsPopupOpen(true)
  }

  const handleRowClick = (rowData: PerformanceTimelineInterface) => {
    if (onRowClick) {
      onRowClick(rowData)
      return
    }

    const { category, cycle, employee_seniority, reviewers, scorecard, status, type } =
      rowData

    if (type === 'pip' || category === ReviewCategory.PIP) {
      const requestQuery = scorecard?.id ? `&${Queries.EditingId}=${scorecard.id}` : ''
      navigateTo(
        `${pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE.PERFORMANCE, {
          id: data.id,
        })}?${Queries.CycleId}=${cycle.id}${requestQuery}`,
      )
      return
    }

    setSidebarDetails(prevData => ({
      ...prevData,
      category,
      cycleId: String(cycle.id),
      cycleName: cycle.name,
      employeeSeniorityId: employee_seniority?.id,
    }))

    if (type === 'review' && status === Statuses.completed) {
      const reviewerId = reviewers[0]?.id
      if (reviewerId) {
        changeQueryParam(Queries.ReviewerId, `${reviewerId}`)
      }
    } else {
      deleteQueryParam(Queries.ReviewerId)
    }

    setSidebarOpen(true)
  }

  const tableData = performanceTimelineTable.table?.data || []
  const cutByLines = 5
  const showBtn = tableData.length > cutByLines

  const filteredTableData =
    !showBtn || rowsExpanded ? tableData : tableData.slice(0, cutByLines)

  return (
    <Cell p={renderHeader ? 's-16' : 0}>
      {performanceTimelineTable.error ? (
        <Flex justifyContent="center" width="100%">
          <AccessError />
        </Flex>
      ) : (
        <Flex flexDirection="column" width="100%">
          {renderHeader && (
            <Flex justifyContent="space-between">
              <Stat
                label={`Latest grade${
                  performanceTimelineTable.tableStats?.cycle?.name
                    ? ` (${performanceTimelineTable.tableStats.cycle.name})`
                    : ''
                }`}
                mb="s-24"
                val={performanceTimelineTable.latestGrade}
              />
              <HStack gap="s-12" mb="s-20">
                {secondaryTabBar}
                {tabBar}
              </HStack>
            </Flex>
          )}
          <SummaryTableContainer>
            {currentTab === ChartTableTabs.Table && (
              <>
                <AdjustableTable
                  hideCountAndButtonSection
                  expandableType="chevron"
                  name={TableNames.PerformanceTimeline}
                  noDataMessage="Performance timeline will appear here"
                  row={performanceTimelineROW(onMessageClick, selectedCycle?.id)}
                  onRowClick={rowData => handleRowClick(rowData)}
                  {...summaryTableProps}
                  {...performanceTimelineTable.table}
                  data={filteredTableData}
                />
                <SummarySidebar
                  employeeId={data.id}
                  employeeSeniorityId={data.seniority?.id}
                  isOpen={Boolean(sidebarOpen && sidebarDetails)}
                  hideCategoryFilter
                  onClose={() => {
                    setSidebarOpen(false)
                    deleteQueryParam(Queries.ReviewerId)
                  }}
                  predefinedReviewer
                  {...sidebarDetails}
                />
              </>
            )}
            {currentTab === ChartTableTabs.Chart && (
              <Flex flex="1 0" flexDirection="column">
                <Box height={400}>
                  <Chart
                    data={getChartData(currentSecondaryTab)}
                    id={data.id}
                    isRating={currentSecondaryTab !== ToggleSecondaryTabs.Grades}
                  />
                </Box>
              </Flex>
            )}
            {isPopupOpen && (
              <Popup
                onClose={() => setIsPopupOpen(false)}
                open={Boolean(isPopupOpen && popupDetails)}
                variant="bottom-sheet"
              >
                <OverallFeedback
                  avatar={popupDetails?.reviewers?.[0].avatar}
                  title={popupDetails?.reviewers?.[0].full_name}
                  subtitle={`Relation: ${popupDetails?.stage}`}
                  name={popupDetails?.reviewers?.[0].display_name}
                  reviewerId={popupDetails?.reviewers?.[0].id}
                  pros={popupDetails?.overall_feedback?.pros}
                  cons={popupDetails?.overall_feedback?.cons}
                  isSidebar
                  disabled
                />
              </Popup>
            )}
          </SummaryTableContainer>
          {showBtn && currentTab === ChartTableTabs.Table && (
            <TextButton onClick={() => setRowsExpanded(!rowsExpanded)} mt="s-8">
              View {rowsExpanded ? 'less' : 'more'}
            </TextButton>
          )}
        </Flex>
      )}
    </Cell>
  )
}

export const PerformanceProgressionAndTimelineSections = ({ data }: CommonProps) => {
  const params = useParams<{ id?: string }>()
  const canViewProgression = canViewProgressionComponent(data)

  return (
    <>
      {canViewProgression && (
        <LoadSectionInView>
          <ProgressionSection data={data} />
        </LoadSectionInView>
      )}
      <Box>
        <SummarySubheader
          formUrl={pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE.TIMELINE, params)}
        >
          Performance history
        </SummarySubheader>
        <LoadSectionInView>
          <PerformanceSection data={data} />
        </LoadSectionInView>
      </Box>
    </>
  )
}

type TalentTable = useTableReturnType<BudgetInterface, BudgetStats, {}>

interface TalentSectionProps extends CommonProps {
  tableRef: (ref: TalentTable) => void
}

const TalentSection = ({ data, tableRef }: TalentSectionProps) => {
  const talentFilter = getTalentTypes(data.id)['direct-report'].filter

  const talentTable = useTalentTable(talentFilter)

  const { data: settings } = useGetOrganisationSettings()

  useEffect(() => {
    tableRef(talentTable)
  }, [talentTable])

  const [loadMoreClicked, loadMoreCicked] = useState(false)

  const hasExpensesPermission = !!data.field_options.permissions?.includes(
    PermissionTypes.ViewEmployeeExpenses,
  )
  const hasPerformancePermission = !!data.field_options.permissions?.includes(
    PermissionTypes.ViewEmployeePerformance,
  )

  const hideSpend = !settings?.enable_budget_management || !hasExpensesPermission

  const hiddenCells: HiddenCells = {
    annual_expense: hideSpend,
    performance_label: !hasPerformancePermission,
  }

  const onCurrencyChange = (currency: string) => {
    talentTable.onFilterChange({
      filters: [{ name: currency, id: currency }],
      columnName: 'target_currency',
      nonResettable: true,
    })
  }

  const onLoadMore = () => {
    loadMoreCicked(true)
  }

  const limit = 10
  const excessEmployees = talentTable.data.length - limit
  const showLoadMore = !loadMoreClicked && excessEmployees > 0

  const displayData = loadMoreClicked
    ? talentTable.data
    : talentTable.data.slice(0, limit)

  return (
    <Cell>
      <Flex flexDirection="column" width="100%">
        <TalentHeader
          id={data.id}
          filterBy={[]}
          tableStats={talentTable.stats}
          type={TalentType.DirectReport}
          showExpensesStats={!hideSpend}
          showPerformanceStats={hasPerformancePermission}
          onCurrencyChange={onCurrencyChange}
          period="annual"
          showExpandedPerformanceStats
        />
        <SummaryTableContainer>
          <AdjustableTable<BudgetInterface, BudgetStats>
            name={TableNames.EmployeeTalent}
            row={talentROW}
            hiddenCells={hiddenCells}
            {...talentTable}
            data={displayData}
            {...summaryTableProps}
            hideCountAndButtonSection
          />
        </SummaryTableContainer>
        {showLoadMore && (
          <TextButton onClick={onLoadMore} mt="s-8" mb="-8px" pl={0}>
            Load more ({excessEmployees})
          </TextButton>
        )}
      </Flex>
    </Cell>
  )
}

const FindingsSection = ({ data }: CommonProps) => {
  const entityType = EntityTypes.employee

  const findingsTable = useTable<AuditInterface>(
    { getItems: getAuditTickets(entityType, data.id) },
    findingsSummaryFilters,
    findingsSummarySorting,
  )

  return (
    <Box mt="s-16">
      <FindingsSummary entityType={entityType} table={findingsTable} />
    </Box>
  )
}
const HiringSection = ({ data }: CommonProps) => {
  const table = useRequisitionTable({
    filterBy: getFilterBy(data),
    sortBy,
    statsData: getStatsData(data),
  })

  const [loadMoreClicked, setLoadMoreClicked] = useState(false)

  const onLoadMore = () => {
    setLoadMoreClicked(true)
  }

  const highPriority = table.data
    .filter(row => row.priority === Priority.High)
    .slice(0, 10)
  const excessEmployees = table.data.length - highPriority.length
  const showLoadMore = !loadMoreClicked && excessEmployees > 0

  const displayData = loadMoreClicked ? table.data : highPriority

  return (
    <Cell>
      <Flex flexDirection="column" width="100%">
        <Flex mb="s-16">
          <Stat
            mr="s-16"
            label="Total Headcount"
            val={table?.stats?.requisition_total_headcount}
          />
          <Stat
            label="Remaining Headcount"
            val={table?.stats?.requisition_remaining_headcount}
          />
        </Flex>

        <SummaryTableContainer>
          <AdjustableTable
            name={TableNames.EmployeeRequisitions}
            row={hiringROW}
            {...table}
            data={displayData}
            {...summaryTableProps}
            noDataMessage={
              !loadMoreClicked && table.data.length > 0
                ? 'No high priority requisitions. Click below to show all'
                : undefined
            }
            hideCountAndButtonSection
          />
        </SummaryTableContainer>
        {showLoadMore && (
          <TextButton onClick={onLoadMore} mt="s-8" mb="-8px" pl={0}>
            Load more ({excessEmployees})
          </TextButton>
        )}
      </Flex>
    </Cell>
  )
}

const ProfileSection = ({ data }: CommonProps) => {
  const isInternal = data.employee_type === 'internal'

  const [expanded, setExpanded] = useState(false)

  const { data: settings } = useGetEmployeeSettings()
  const positionHeader = useSectionHeader({ employeeId: `${data.id}`, type: 'position' })
  const {
    hasPendingChanges: hasPendingOrganisationChanges,
    ...organisationSectionProps
  } = useSectionHeader({ employeeId: `${data.id}`, type: 'organisation' })

  const positionAndOrganisationApi = (section: 'position' | 'organisation') => {
    const fields: Record<typeof section, string[]> = {
      position: [
        'specialisation',
        'seniority',
        'specialisation_seniority_sublevel',
        'job_title',
      ],
      organisation: ['team', 'line_manager', 'quality_control'],
    }

    return isInternal
      ? getEmployeeFields(data.id, fields[section])
      : getContractorFields(data.id, fields[section])
  }

  return (
    <Box mt="s-16">
      <Flex justifyContent="space-between" alignItems="center">
        <Text color="grey-tone-50" fontWeight={500}>
          Profile information
        </Text>
        <Action onClick={() => setExpanded(!expanded)}>
          View {expanded ? 'less' : 'more'}
        </Action>
      </Flex>
      <TransitionCollapse duration={200} in={expanded}>
        <FormPreview<InternalOrExternalEmployee>
          api={() => positionAndOrganisationApi('position')}
          title="Position"
          customHeader={positionHeader.customHeader}
        >
          <Group>
            <FormPreview.Item<InternalOrExternalEmployee>
              title="Role (Specialisation)"
              field="specialisation.name"
              to={position =>
                pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, {
                  id: position.specialisation?.id,
                })
              }
            />
            <FormPreview.Item<EmployeeInterface>
              title="Seniority"
              hidden={!isInternal}
              insert={e => (
                <Flex>
                  <Text>{e.seniority?.name || '-'}</Text>
                  {e.specialisation_seniority_sublevel && (
                    <Text pl="s-6">({e.specialisation_seniority_sublevel.name})</Text>
                  )}
                </Flex>
              )}
            />
            <FormPreview.Item
              title="Job title"
              field="job_title"
              hidden={!isInternal || !settings?.enable_job_title}
            />
          </Group>
        </FormPreview>

        <FormPreview<InternalOrExternalEmployee>
          api={() => positionAndOrganisationApi('organisation')}
          title="Organisation"
          {...organisationSectionProps}
        >
          <ChangeRequestsPendingWidget
            id={data.id}
            isVisible={hasPendingOrganisationChanges}
          />
          <Group>
            <FormPreview.Item<InternalOrExternalEmployee>
              title="Team"
              field="team.name"
              to={organisation =>
                // @ts-ignore FIXME: REVPI-19 support optional team value
                pathToUrl(ROUTES.FORMS.TEAM.SUMMARY, { id: organisation.team.id })
              }
            />
            <FormPreview.Item<EmployeeInterface>
              title={isInternal ? 'Line manager' : 'Point of contact 1'}
              field="line_manager.name"
              to={organisation =>
                pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
                  id: organisation.line_manager.id,
                })
              }
            />
            <FormPreview.Item<EmployeeInterface>
              title="Functional manager"
              field="quality_control.name"
              to={organisation =>
                pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
                  id: organisation.quality_control?.id,
                })
              }
              hidden={!isInternal}
            />
          </Group>
        </FormPreview>
      </TransitionCollapse>
    </Box>
  )
}
