import React, { useMemo } from 'react'
import useResizeObserver from 'use-resize-observer'
import { Cell, Label, Pie, PieChart as PieChartComponent, Legend } from 'recharts'
import type { PolarViewBox } from 'recharts/types/util/types'
import { Box, Token } from '@revolut/ui-kit'
import { formatMoney } from '@src/utils/format'
import { PieLegend } from './PieLegend'

import { ChartSkeleton } from '@src/pages/Forms/QueryForm/components/Charts/ChartSkeleton'
import { ResponsiveContainer } from '@src/pages/Forms/QueryForm/components/Charts/ResponsiveContainer'
import {
  getColorByIndex,
  getSecondaryShapeColor,
} from '@src/pages/Forms/QueryForm/components/Charts/helpers'

const END_ANGLE = -40
const START_ANGLE = 220
const INNER_CIRCLE_SIZE = 20
const OUTER_CIRCLE_SIZE = 40
const CORNER_RADIUS = 50
const PADDING_ANGLE = 3
const ANIMATION_DURATION = 500

type ListItem = {
  name: string
  value: number
}

type PieGraphProps = {
  currency?: string
  data: ListItem[]
  isLoading?: boolean
  total?: number
}

export const PieChart = ({ currency, data, isLoading, total }: PieGraphProps) => {
  const { ref, width, height } = useResizeObserver<HTMLDivElement>()
  const fallbackHeight = height || 300

  const innerCircleRadius = fallbackHeight / 4 + INNER_CIRCLE_SIZE
  const outerCircleRadius = fallbackHeight / 4 + OUTER_CIRCLE_SIZE

  const pieChartCells = useMemo(() => {
    return data.map((_, index) => {
      return (
        <Cell
          key={`outer-cell-${index}`}
          fill={getSecondaryShapeColor(Token.colorChannel[getColorByIndex(index)])}
          strokeWidth={0}
          style={{ outline: 'none' }}
        />
      )
    })
  }, [data])

  if (isLoading) {
    return <ChartSkeleton height={fallbackHeight} />
  }

  return (
    <Box ref={ref} flex={1} height="100%" width="100%">
      {height ? (
        <ResponsiveContainer height={height} width="100%">
          <PieChartComponent width={width} height={height}>
            <Pie
              cx="50%"
              cy="60%"
              data={data}
              dataKey="value"
              innerRadius={innerCircleRadius}
              outerRadius={outerCircleRadius}
              startAngle={START_ANGLE}
              endAngle={END_ANGLE}
              cornerRadius={CORNER_RADIUS}
              paddingAngle={PADDING_ANGLE}
              animationDuration={ANIMATION_DURATION}
            >
              {pieChartCells}
              <Label
                content={props => {
                  const viewBox = (props.viewBox || {}) as PolarViewBox
                  const cx = Number(viewBox.cx || 0)
                  const cy = Number(viewBox.cy || 0)

                  const positioningProps = {
                    x: cx,
                    y: cy,
                    textAnchor: 'middle',
                    verticalAnchor: 'middle',
                  }

                  return (
                    <text
                      {...positioningProps}
                      fill={Token.color.foreground}
                      style={{ fontWeight: 700, fontSize: '32px' }}
                    >
                      {formatMoney(total, currency)}
                    </text>
                  )
                }}
              />
            </Pie>

            <Legend content={props => <PieLegend currency={currency} {...props} />} />
          </PieChartComponent>
        </ResponsiveContainer>
      ) : null}
    </Box>
  )
}
