import React, { useMemo } from 'react'
import { Box, Flex, Icon, HStack, Text, Token, Widget } from '@revolut/ui-kit'
import { useGetEmployeeTotalCompensationTimeSeries } from '@src/api/totalCompensation'
import Tooltip from '@components/Tooltip/Tooltip'
import { CompensationParams } from '@src/pages/EmployeeProfile/Layout/common/types'
import { BarChart } from '@src/pages/Forms/QueryForm/components/Charts/BarChart'
import { formatMoney } from '@src/utils/format'
import { BarChartTooltip } from '../Charts/BarChartTooltip'

type Props = {
  compensationParams: CompensationParams
  id: number
}

const DATA_KEYS = [
  {
    id: 0,
    label: 'Base salary',
    value: 'base_salary',
  },
  {
    id: 1,
    label: 'Vested equity',
    value: 'vested_equity',
  },
  {
    id: 2,
    label: 'Cash bonus',
    value: 'cash_bonus',
  },
]

export const CompensationOverTime = ({ compensationParams, id }: Props) => {
  const { currency } = compensationParams
  const filters = currency
    ? [
        {
          filters: [{ id: `${currency}`, name: `${currency}` }],
          columnName: 'currency_iso_code',
        },
      ]
    : []

  const { data, isLoading } = useGetEmployeeTotalCompensationTimeSeries(id, filters)

  const chartData = useMemo(() => {
    if (!data) {
      return []
    }

    return data.map(value => ({
      label: '',
      tooltip: '',
      ...value,
    }))
  }, [data])

  if (!isLoading && !data?.length) {
    return null
  }

  const projectedDataIdx: number[] = []

  data?.forEach((value, i) => {
    if (value.year > new Date().getFullYear()) {
      projectedDataIdx.push(i)
    }
  })

  return (
    <Widget height="55vh" p="s-16" data-testid="compensation_over_time_section">
      <Flex flexDirection="column" height="100%">
        <HStack gap="s-4">
          <Text variant="emphasis2">Compensation over time</Text>
          <Tooltip
            placement="bottom"
            text="Review your past earnings and predictions for future equity, including salary, equity, and bonuses."
          >
            <Icon name="InfoOutline" size={16} />
          </Tooltip>
        </HStack>

        <Box flex={1}>
          <BarChart
            barOptions={{
              maxBarSize: 150,
              radius: 6,
              style: { stroke: Token.color.widgetBackground, strokeWidth: 2.5 },
            }}
            data={chartData}
            dataKeys={DATA_KEYS}
            isLoading={isLoading}
            isStacked
            hideLabelList
            projectedDataIdx={projectedDataIdx}
            tooltip={BarChartTooltip}
            xAxis={{ axisLine: false, dataKey: 'year' }}
            yAxis={{
              orientation: 'right',
              tickFormatter: value => formatMoney(value, data?.[0].currency.iso_code),
            }}
          />
        </Box>
      </Flex>
    </Widget>
  )
}
