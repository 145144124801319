import React from 'react'
import get from 'lodash/get'
import { Text } from '@revolut/ui-kit'

import { getStatusColor } from '@src/components/CommonSC/General'
import { IdAndName } from '@src/interfaces'
import { IdStatuses } from '@src/interfaces/employees'
import {
  PayCycleInterface,
  PayCycleStatus,
  PayrollTimelineChangeInterface,
  PayrollTimelineDomainName,
  PayrollTimelineEventInterface,
} from '@src/interfaces/payrollV2'
import { formatDate, formatWithoutTimezone } from '@src/utils/format'
import { formatSnakeCase } from '@src/utils/string'

export type RouteParams = { id: string }

export type CycleSelectorOptionsData = {
  options: CycleOption[]
  isLoading: boolean
}

export type CycleOption = IdAndName & {
  statusId: PayCycleStatus
}

export type CommonTabProps = {
  data: PayCycleInterface
  selectedCycle: CycleOption | undefined
  setSelectedCycle: (newCycle: CycleOption) => void
  cycleSelectorOptions: CycleOption[]
  isCycleSelectorLoading: boolean
}

export type FieldConfigType = 'date' | 'dateTime' | 'snakeCase'

export type FieldConfig = {
  path: string
  label?: string
  type?: FieldConfigType
  insert?: (value: string | number) => React.ReactNode
  omitFrom?: boolean
}

const typeToFormatter: Record<
  FieldConfigType,
  (data: string | number) => React.ReactNode
> = {
  date: d => (d ? formatDate(String(d)) : ''),
  dateTime: d => (d ? formatWithoutTimezone(String(d), true) : ''),
  snakeCase: id => (id ? formatSnakeCase(String(id)) : ''),
}

export const domainNameToFieldsConfig: Record<
  PayrollTimelineDomainName,
  Array<string | FieldConfig>
> = {
  // Work details:
  name: ['name', 'full_name', 'first_name', 'middle_name', 'last_name'],
  status: [
    {
      path: 'status',
      label: 'Employee status',
      insert: statusId => (
        <Text color={getStatusColor(statusId as IdStatuses)}>
          {formatSnakeCase(String(statusId))}
        </Text>
      ),
    },
  ],
  end_of_probation: ['end_of_probation_date_time'],
  employee_type: ['employee_type'],

  // Contract:
  contract_type: [{ path: 'contract_type', type: 'snakeCase' }],
  contract_status: [
    {
      path: 'contract_status',
      insert: statusId => (
        <Text color={getStatusColor(statusId as IdStatuses)}>
          {formatSnakeCase(String(statusId))}
        </Text>
      ),
    },
  ],
  contract_term: ['contract_term'],
  start_date: [{ path: 'start_date', type: 'date' }],
  end_date: [{ path: 'end_date', type: 'date' }],
  entity: ['entity'],
  location: ['location'],
  salary: [
    'salary_amount',
    'salary_currency',
    { path: 'salary_payment_frequency', type: 'snakeCase' },
    { path: 'salary_time_unit', type: 'snakeCase' },
  ],
  sign_on_bonus: ['sign_on_bonus_amount', 'sign_on_bonus_currency', 'sign_on_bonus_type'],
  position: ['position'],
  specialisation: ['specialisation'],
  seniority: ['seniority', 'specialisation_seniority_sublevel'],
  job_title: ['title'],
  weekly_working_hours: ['weekly_working_hours'],
  full_time_equivalent: ['full_time_equivalent'],
  notice_period_during_probation: [
    { path: 'notice_period_during_probation', type: 'snakeCase' },
    { path: 'notice_period_during_probation_unit', type: 'snakeCase' },
  ],
  notice_period_after_probation: [
    { path: 'notice_period_after_probation', type: 'snakeCase' },
    { path: 'notice_period_after_probation_unit', type: 'snakeCase' },
  ],
  inactivity: [
    { path: 'inactivity_reason', type: 'snakeCase' },
    { path: 'inactivity_start_date', type: 'date' },
    { path: 'inactivity_end_date', type: 'date' },
  ],
  approval_status: [
    {
      path: 'approval_status',
      insert: statusId => (
        <Text color={getStatusColor(statusId as IdStatuses)}>
          {formatSnakeCase(String(statusId))}
        </Text>
      ),
    },
  ],

  // Personal details:
  personal_email: ['personal_email'],
  legal_sex: ['legal_sex'],
  marital_status: ['marital_status'],
  birth_date: ['birth_date'],
  phone_number: ['phone_number', 'phone_number_short', 'phone_country_code'],
  nationality: ['nationality'],
  nationalities: ['nationalities'],
  languages: ['languages'],
  address: [
    'country',
    'county',
    'city',
    'address_line_1',
    'address_line_2',
    'address_line_3',
    'post_code',
  ],
  emergency_contact: [
    'emergency_contact_full_name',
    'emergency_contact_email',
    'emergency_contact_mobile_phone',
    'emergency_contact_phone_country_code',
    'emergency_contact_phone_number',
    'emergency_contact_relationship',
  ],

  // Time Off:
  time_off_request: [
    'duration',
    'from_date_time',
    { path: 'start_date_time', type: 'date' },
    { path: 'end_date_time', type: 'date' },
    'total_duration',
    'balance',
    'unit',
  ],
}

const isConfig = (field: FieldConfig | string): field is FieldConfig =>
  typeof field === 'object' && 'path' in field

const isInsertable = (value: unknown): value is string | number =>
  typeof value === 'string' || typeof value === 'number'

const parseNum = (value: string | number) => {
  if (typeof value === 'number') {
    return value
  }
  if (!isNaN(Number(value))) {
    return Number(value)
  }
  return undefined
}

export type ChangeType = 'create' | 'increase' | 'decrease' | 'none' | 'unknown'
export type ParsedDomainFieldChanges = {
  from: React.ReactNode
  to: React.ReactNode
  label: string
  changeType: ChangeType
}
export const parseDomainFieldChanges = (
  data: PayrollTimelineChangeInterface,
  fieldConf: FieldConfig | string,
): ParsedDomainFieldChanges => {
  const changesFrom = data.from_value
  const changesTo = data.to_value

  const fieldPath = isConfig(fieldConf) ? fieldConf.path : fieldConf
  const fieldLabel =
    isConfig(fieldConf) && fieldConf.label
      ? fieldConf.label
      : formatSnakeCase(fieldPath.split('.')[0])

  const omitFrom = isConfig(fieldConf) ? fieldConf.omitFrom : undefined
  const insert = isConfig(fieldConf) ? fieldConf.insert : undefined
  const type = isConfig(fieldConf) ? fieldConf.type : undefined

  let from
  let to
  let changeType: ChangeType = 'none'

  const nothingChanged = { from, to, label: fieldLabel, changeType: 'none' as const }

  if (!get(changesTo, fieldPath)) {
    return nothingChanged
  }
  const fromValue = omitFrom ? undefined : get(changesFrom, fieldPath)
  const toValue = get(changesTo, fieldPath)

  from = parseNum(fromValue) || fromValue
  to = parseNum(toValue) || toValue

  if (from === to) {
    return nothingChanged
  }
  if (typeof from === 'number' && typeof to === 'number') {
    changeType =
      from < to ? ('increase' as const) : from > to ? ('decrease' as const) : 'none'
  } else if (!from) {
    changeType = 'create'
  } else {
    changeType = 'unknown' as const
  }

  if (type) {
    const format = typeToFormatter[type]
    from = format?.(from)
    to = format?.(to)
  } else if (insert) {
    from = isInsertable(from) ? insert(from) : from
    to = isInsertable(to) ? insert(to) : to
  }

  return {
    from,
    to,
    label: fieldLabel,
    changeType,
  }
}

export const getNonEmptyChanges = (data: PayrollTimelineEventInterface) => {
  const fields = domainNameToFieldsConfig[data.content.domain_name] || []
  let res = []

  for (let i = 0; i < fields.length; i++) {
    const { from, to, label, changeType } = parseDomainFieldChanges(
      data.content,
      fields[i],
    )
    if (to && changeType !== 'none') {
      res.push({ label, from, to, changeType })
    }
  }
  return res
}
