import React, { useMemo } from 'react'
import { Box, Flex, Icon, HStack, Text, Token, Widget } from '@revolut/ui-kit'
import { useGetEmployeeEquityTimeSeries } from '@src/api/totalCompensation'
import Tooltip from '@components/Tooltip/Tooltip'
import { CompensationParams } from '@src/pages/EmployeeProfile/Layout/common/types'
import { BarChart } from '@src/pages/Forms/QueryForm/components/Charts/BarChart'
import { formatMoney } from '@src/utils/format'
import { BarChartTooltip } from '../Charts/BarChartTooltip'

type Props = {
  compensationParams: CompensationParams
  id: number
}

export const EquityGranted = ({ compensationParams, id }: Props) => {
  const { currency } = compensationParams
  const filters = currency
    ? [
        {
          filters: [{ id: `${currency}`, name: `${currency}` }],
          columnName: 'currency_iso_code',
        },
      ]
    : []

  const { data, isLoading } = useGetEmployeeEquityTimeSeries(id, filters)

  const dataKeys = useMemo(() => {
    if (!data) {
      return []
    }

    const allSeries = data.flatMap(item => item.series)

    return [...new Set(allSeries.map(item => item.category))].map((category, index) => ({
      id: index,
      label: category,
      value: category,
    }))
  }, [data])

  const chartData = useMemo(() => {
    if (!data) {
      return []
    }

    return data.map(value => {
      const categories = value.series.reduce(
        (o, key) => ({ ...o, [key.category]: key.amount }),
        {},
      )

      return {
        label: '',
        tooltip: '',
        ...value,
        ...categories,
      }
    })
  }, [data])

  if (!isLoading && !data?.length) {
    return null
  }

  const projectedDataIdx: number[] = []

  chartData.forEach((value, i) => {
    if (value.year > new Date().getFullYear()) {
      projectedDataIdx.push(i)
    }
  })

  return (
    <Widget height="55vh" p="s-16" data-testid="granted_equity_section">
      <Flex flexDirection="column" height="100%">
        <HStack gap="s-4">
          <Text variant="emphasis2">Granted equity</Text>
          <Tooltip
            placement="bottom"
            text="The total equity awarded to you, including both vested and unvested shares."
          >
            <Icon name="InfoOutline" size={16} />
          </Tooltip>
        </HStack>

        <Box flex={1}>
          <BarChart
            barOptions={{
              maxBarSize: 150,
              radius: 6,
              style: { stroke: Token.color.widgetBackground, strokeWidth: 2.5 },
            }}
            data={chartData}
            dataKeys={dataKeys}
            isLoading={isLoading}
            isStacked
            hideLabelList
            projectedDataIdx={projectedDataIdx}
            tooltip={BarChartTooltip}
            xAxis={{ axisLine: false, dataKey: 'year' }}
            yAxis={{
              orientation: 'right',
              tickFormatter: value => formatMoney(value, data?.[0].currency.iso_code),
            }}
          />
        </Box>
      </Flex>
    </Widget>
  )
}
