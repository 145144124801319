import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import {
  CandidatesListInterface,
  CandidateActiveActionType,
  InterviewRoundState,
  InterviewStatuses,
} from '@src/interfaces/interviewTool'
import { selectorKeys } from '@src/constants/api'
import React from 'react'
import { Box, Tag, TagBar, Text, TextButton, textChain, Token } from '@revolut/ui-kit'
import isSameDay from 'date-fns/isSameDay'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { formatDate } from '@src/utils/format'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import AddInterviewFeedbackAction from '@components/AddInterviewFeedbackAction/AddInterviewFeedbackAction'
import { formatDistanceToNowStrict } from 'date-fns'
import upperFirst from 'lodash/upperFirst'
import { getRequisitionsSelectorOptions } from '@src/api/requisitions'
import Tooltip from '@components/Tooltip/Tooltip'
import { formatExperienceDateRange } from '@src/utils/hiring'
import TextWithMoreCount from '@components/TextWithMoreCount/TextWithMoreCount'
import styled from 'styled-components'
import Table from '@components/TableV2/Table'
import capitalize from 'lodash/capitalize'
import { uniqBy } from 'lodash'
import { useIsNewTable } from '@components/TableV2/hooks'
import TableCellLink from '@components/TableCellLink/TableCellLink'
import { IdAndName } from '@src/interfaces'

const StyledTooltip = styled(Tooltip)`
  justify-content: flex-start;
`

export const getInterviewStateColor = (state?: InterviewRoundState) => {
  switch (state) {
    case InterviewRoundState.archived:
      return Token.color.greyTone50

    case InterviewRoundState.hired:
      return Token.color.green

    default:
      return Token.color.foreground
  }
}

export const CandidateNameCell = ({ id, name }: IdAndName) => {
  const isNewTable = useIsNewTable()

  if (!isNewTable) {
    return (
      <TableCellLink to={pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, { id })}>
        {name}
      </TableCellLink>
    )
  }

  return (
    <UserWithAvatar
      size={40}
      full_name={name}
      id={id}
      avatarUrlFunc={candidateId =>
        pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, { id: candidateId })
      }
    />
  )
}

export const candidateNameColumn: ColumnInterface<CandidatesListInterface> = {
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'full_name',
  sortKey: 'full_name',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Name',
  isBig: true,
  insert: ({ data }) => <CandidateNameCell id={data.id} name={data.full_name} />,
}

export const candidateSpecialisationColumn: ColumnInterface<CandidatesListInterface> = {
  type: CellTypes.text,
  idPoint: 'active_interview_round.specialisation.id',
  dataPoint: 'active_interview_round.specialisation.name',
  sortKey: 'active_specialisation__name',
  filterKey: 'active_specialisation',
  selectorsKey: selectorKeys.specialisations,
  title: 'Role',
}

export const candidateSeniorityColumn: ColumnInterface<CandidatesListInterface> = {
  type: CellTypes.insert,
  idPoint: 'active_interview_round.seniority.id',
  dataPoint: 'active_interview_round.seniority.name',
  sortKey: 'active_interview_round__seniority__level',
  filterKey: 'active_interview_round__seniority',
  selectorsKey: selectorKeys.seniority,
  title: 'Seniority',
  insert: ({ data }) => (
    <Table.SeniorityCell seniority={data?.active_interview_round?.seniority?.name} />
  ),
}

export const candidateTagsColumn: ColumnInterface<CandidatesListInterface> = {
  type: CellTypes.insert,
  idPoint: 'tags',
  dataPoint: 'tags',
  sortKey: null,
  filterKey: 'tags',
  selectorsKey: selectorKeys.candidate_tags,
  title: 'Tags',
  notHoverable: true,
  insert: ({ data }) => {
    if (!data.tags?.length) {
      return null
    }
    return (
      <TagBar>
        {data.tags.map(tag => (
          <Tag key={tag.id} variant="faded">
            {tag.name}
          </Tag>
        ))}
      </TagBar>
    )
  },
}

export const candidateRecruiterColumn: ColumnInterface<CandidatesListInterface> = {
  type: CellTypes.insert,
  idPoint: 'recruiter.id',
  dataPoint: 'recruiter.display_name',
  sortKey: 'recruiter__full_name',
  filterKey: 'recruiter',
  selectorsKey: selectorKeys.employee,
  title: 'Recruiter',
  insert: ({ data }) => (data.recruiter ? <UserWithAvatar {...data.recruiter} /> : '-'),
}

export const candidateInterviewStageColumn: ColumnInterface<CandidatesListInterface> = {
  type: CellTypes.text,
  idPoint: 'active_interview_round.latest_interview_stage.interview_type',
  dataPoint: 'active_interview_round.latest_interview_stage.title',
  sortKey: 'active_interview_round__latest_interview_stage__title',
  filterKey: 'active_interview_round__latest_interview_stage__hiring_stage',
  selectorsKey: selectorKeys.specialisation_hiring_stages,
  colors: () => Token.color.greyTone50,
  title: 'Stage',
}

export const candidateLastActivityDateColumn: ColumnInterface<CandidatesListInterface> = {
  type: CellTypes.insert,
  idPoint: 'active_interview_round.latest_interview_stage_updated_date_time',
  dataPoint: 'active_interview_round.latest_interview_stage_updated_date_time',
  sortKey: 'active_interview_round__latest_interview_stage_updated_date_time',
  filterKey: 'active_interview_round__latest_interview_stage_updated_date_time',
  filterType: FilterType.date,
  selectorsKey: selectorKeys.none,
  title: 'Stage changed',
  insert: ({ data }) => {
    const date = data.active_interview_round?.latest_interview_stage_updated_date_time
    if (!date) {
      return '-'
    }

    return (
      <Box>
        <Text>
          {isSameDay(new Date(), new Date(date))
            ? 'Today'
            : formatDistanceToNowStrict(new Date(date), { addSuffix: true })}
        </Text>
        <Text pl="s-4" color={Token.color.greyTone50}>
          ({formatDate(date)})
        </Text>
      </Box>
    )
  },
}

export const candidateStatusColumn: ColumnInterface<CandidatesListInterface> = {
  type: CellTypes.insert,
  idPoint: 'active_interview_round.latest_interview_stage.scheduling_status',
  dataPoint: 'active_interview_round.latest_interview_stage.scheduling_status_display',
  sortKey: 'active_interview_round__latest_interview_stage__scheduling_status',
  filterKey: 'active_interview_round__latest_interview_stage__scheduling_status',
  selectorsKey: selectorKeys.interview_stage_scheduling_statuses,
  title: 'Status',
  insert: ({ data }) => {
    const id = data.active_interview_round?.latest_interview_stage?.scheduling_status
    const name =
      data.active_interview_round?.latest_interview_stage?.scheduling_status_display

    return id && name ? <Table.StatusCell status={{ id, name }} /> : '-'
  },
}

export const candidateStateColumn: ColumnInterface<CandidatesListInterface> = {
  type: CellTypes.insert,
  idPoint: 'active_interview_round.state',
  dataPoint: 'active_interview_round.state',
  sortKey: 'active_interview_round__state',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Candidate Status',
  insert: ({ data }) => {
    return (
      <Text color={getInterviewStateColor(data.active_interview_round?.state)}>
        {upperFirst(data.active_interview_round?.state) || '-'}
      </Text>
    )
  },
}

export const candidateOriginColumn: ColumnInterface<CandidatesListInterface> = {
  type: CellTypes.text,
  idPoint: 'origin',
  dataPoint: 'origin',
  sortKey: 'origin',
  filterKey: 'origin',
  selectorsKey: selectorKeys.candidate_origin_choices,
  title: 'Origin',
}

export const candidateActionColumn: ColumnInterface<CandidatesListInterface> = {
  type: CellTypes.insert,
  idPoint: 'action',
  dataPoint: 'action',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Action',
  insert: ({ data }) => {
    const interview = data.active_interview_round?.latest_interview_stage
    const status = interview?.scheduling_status

    switch (status) {
      case InterviewStatuses.not_started:
      case InterviewStatuses.pending_scheduling:
        return (
          <TextButton
            fontWeight={500}
            onClick={e => {
              e.stopPropagation()

              navigateTo(pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, { id: data.id }), {
                sidebar: {
                  type: 'schedule',
                  mode: 'scheduling',
                } as CandidateActiveActionType,
              })
            }}
          >
            Schedule
          </TextButton>
        )

      case InterviewStatuses.interview_scheduled:
      case InterviewStatuses.feedback_submitted:
      case InterviewStatuses.pending_cv_screening:
        return <AddInterviewFeedbackAction interview={interview} candidate={data} />

      default:
        return '-'
    }
  },
}

export const candidateInterviewerColumn: ColumnInterface<CandidatesListInterface> = {
  type: CellTypes.insert,
  idPoint: 'latest_interviewer',
  dataPoint: 'latest_interviewer',
  sortKey: 'latest_interviewer',
  filterKey: 'latest_interviewer',
  selectorsKey: selectorKeys.employee,
  title: 'Interviewer',
  insert: ({ data }) =>
    data.latest_interviewer ? <UserWithAvatar {...data.latest_interviewer} /> : '-',
}

export const candidateNextInterviewColumn: ColumnInterface<CandidatesListInterface> = {
  type: CellTypes.dateTime,
  idPoint: 'latest_event_date_time',
  dataPoint: 'latest_event_date_time',
  sortKey: 'latest_event_date_time',
  filterKey: 'latest_event_date_time',
  filterType: FilterType.date,
  selectorsKey: selectorKeys.none,
  title: 'Next interview date',
}

export const candidateOfferStartDateColumn: ColumnInterface<CandidatesListInterface> = {
  type: CellTypes.date,
  idPoint: 'last_offer_sent.anticipated_start_date',
  dataPoint: 'last_offer_sent.anticipated_start_date',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Start date',
}

export const candidateOfferLocationColumn: ColumnInterface<CandidatesListInterface> = {
  type: CellTypes.text,
  idPoint: 'last_offer_sent.location.id',
  dataPoint: 'last_offer_sent.location.name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Offer location',
}

export const candidateOfferTeamColumn: ColumnInterface<CandidatesListInterface> = {
  type: CellTypes.text,
  idPoint: 'last_offer_sent.team.id',
  dataPoint: 'last_offer_sent.team.name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Team',
}

export const candidateOfferApprovalStatusColumn: ColumnInterface<CandidatesListInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'last_offer_sent.status',
    dataPoint: 'last_offer_sent.status',
    sortKey: 'last_offer_sent__status',
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Approval status',
    insert: ({ data }) =>
      data.last_offer_sent ? (
        <Table.StatusCell
          status={{
            id: data.last_offer_sent.status,
            name: capitalize(data.last_offer_sent.status),
          }}
        />
      ) : (
        '-'
      ),
  }

export const createCandidateRequisitionColumn = (
  isConfidential?: boolean,
): ColumnInterface<CandidatesListInterface> => ({
  type: CellTypes.text,
  idPoint: 'active_interview_round.requisition.requisition_title',
  dataPoint: 'active_interview_round.requisition.requisition_title',
  sortKey: 'active_interview_round__requisition',
  filterKey: 'active_interview_round__requisition',
  selectorsKey: () => getRequisitionsSelectorOptions(isConfidential),
  title: 'Requisition',
})

type CompanyType = { name: string; endDate?: string | null; startDate?: string | null }

export const candidateWorkExperienceColumn: ColumnInterface<CandidatesListInterface> = {
  type: CellTypes.insert,
  idPoint: 'work_experiences',
  dataPoint: 'work_experiences',
  sortKey: 'current_work_experience__company__name',
  filterKey: 'companies',
  selectorsKey: selectorKeys.work_experience_companies,
  title: 'Company',
  insert: ({ data }) => {
    let companies: CompanyType[] = []
    if (data.headline) {
      companies.push({ name: data.headline })
    }

    if (data.work_experiences?.length) {
      companies.push(
        ...data.work_experiences.reduce<CompanyType[]>((acc, item) => {
          const name = item.company?.name || item.other_company_name

          if (name) {
            acc.push({
              name,
              endDate: item.end_date,
              startDate: item.start_date,
            })
          }

          return acc
        }, []),
      )
    }

    if (!companies.length) {
      return '-'
    }

    companies = uniqBy(companies, 'name')

    const label = companies
      .map(item =>
        textChain(
          item.startDate && formatExperienceDateRange(item.startDate, item.endDate),
          item.name,
        ),
      )
      .join('\n')

    return (
      <StyledTooltip placement="top" useFormatting text={label}>
        <TextWithMoreCount
          hideSuffix
          visibleCount={3}
          items={companies.map(item => (
            <Text
              key={item.name}
              color={item.endDate ? Token.color.greyTone50 : undefined}
            >
              {item.name}
            </Text>
          ))}
        />
      </StyledTooltip>
    )
  },
}

export const candidateYearsOfExperienceColumn: ColumnInterface<CandidatesListInterface> =
  {
    type: CellTypes.text,
    idPoint: 'years_of_experience',
    dataPoint: 'years_of_experience',
    sortKey: 'years_of_experience',
    filterKey: 'years_of_experience',
    selectorsKey: selectorKeys.none,
    filterType: FilterType.range,
    title: 'Years of exp',
  }

export const candidateSnoozedUntilDateColumn: ColumnInterface<CandidatesListInterface> = {
  type: CellTypes.date,
  filterType: FilterType.shortDate,
  idPoint: 'active_snoozing.snooze_until',
  dataPoint: 'active_snoozing.snooze_until',
  sortKey: 'active_snoozing__snooze_until',
  filterKey: 'active_snoozing__snooze_until',
  selectorsKey: selectorKeys.none,
  title: 'Snoozed until',
}
